import React from 'react';
import {Grid, TextField, Theme, Typography} from "@mui/material";
import {SamlResponse} from "../../models/SamlResponse";
import {StringUtils} from "../../utils/StringUtils";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: 8,
                width: '100%',
            },
        },
    }),
);


const SAMLProviderForm = ({samlProvider}: { samlProvider: SamlResponse }) => {

    const classes = useStyles();

    return <form className={classes.root} style={{ marginBottom: 30}} autoComplete="off">

        <Typography variant="h5" color={"textPrimary"} align={"left"}>SAML Configuration</Typography>

        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Typography color={"textSecondary"} align={"left"}>Provider Name</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={"textSecondary"} align={"left"}>IDP Identifiers</Typography>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField
                    id="providerName"
                    label={"Provider Name"}
                    value={samlProvider.providerName}
                    InputProps={{
                        readOnly: false,
                    }}
                    variant="outlined"
                    fullWidth={true}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id="idpIdentifiers"
                    label="IDP Identifiers"
                    value={samlProvider.idpIdentifiers}
                    InputProps={{
                        readOnly: false,
                        style: {
                            padding: 0
                        }
                    }}
                    variant="outlined"
                    fullWidth={true}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Typography color={"textSecondary"} align={"left"}>Attribute Mapping</Typography>
            </Grid>
        </Grid>
        {
            Object.entries(samlProvider.attributeMapping).map(
                ([key, val], index) => {
                    return (
                        <Grid container spacing={1} key={`${samlProvider.providerName}-attribute-${key}`}>
                            <Grid item xs={6}>
                                <TextField
                                    id={key + "_from"}
                                    label={"From"}
                                    value={key}
                                    InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                    variant="filled"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id={key + "_to"}
                                    label={"To"}
                                    value={val as String}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )
                }
            )
        }
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Typography color={"textSecondary"} align={"left"}>Additional Settings</Typography>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            {
                ["idpSignout", "metadataFile", "metadataURL"].map(
                    key => {
                        return (
                            <Grid item xs={6} key={`${samlProvider.providerName}-${key}`}>
                                <TextField
                                    id={key}
                                    label={StringUtils.keyToLabel(key)}
                                    value={(samlProvider[key] == null ? '' : samlProvider[key]) as String}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        )
                    }
                )
            }
        </Grid>
    </form>
}

export default SAMLProviderForm;
