import React, {useState} from 'react';

import ClientService from "../../services/ClientService";
import {useFormControls} from "../common/FormControls";

import {Button, Snackbar, TextField, Theme, Alert as SnackAlert, Grid} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import {createStyles, makeStyles} from "@mui/styles";
import {GeneratedPdfResponse} from "../../models/GeneratedPdfResponse";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: 8,
                width: '95%',
            },
        },
        start_date: {
            width: '48%', paddingRight: '1%'
        }
        ,
        end_date: {
            width: '48%', paddingLeft: '0%'
        }
    })
);


const initialFormValues = {
    "clientName": "",
    "folderName" : "",
    "contactEmail" : "",
    "candidatePolicyUrl": "",
    "employeePolicyUrl": ""
}

const formFields : {
    name: string,
    label: string,
    isRequired?: boolean,
    type?: 'Email' | 'URL' | 'COLOUR' | 'Number' | 'PlainText'
}[] = [
    {
        name: 'folderName',
        label: 'Folder Name (S3 Folder Path)',
        isRequired: true,
    },
    {
        name: 'clientName',
        label: 'Client Name',
        isRequired: true,
    },
    {
        name: 'contactEmail',
        label: 'Contact Email',
        isRequired: true,
        type: "Email",
    },
    {
        name: 'candidatePolicyUrl',
        label: 'Privacy Policy URL for Candidate (Optional. Leave empty to use default Xydus Policy.)'
    },
    {
        name: 'employeePolicyUrl',
        label: 'Privacy Policy URL for Employee (Optional. Leave empty to use default Xydus Policy.)'
    }
]

const GeneratePdfForm = () => {
    const classes = useStyles();
    const [output, setOutput] = useState<string>("");

    const {
        handleInputValue,
        journeyParamInputHandler,
        handleFormSubmit,
        formIsValid,
        errors,
        openSuccessSnackbar,
        openFailureSnackbar,
        handleSuccessOnClose,
        handleFailureOnClose,
        values
    } = useFormControls({
        initialFormValues: initialFormValues,
        formAction: ({data, onSuccess, onFailure})=>{
            setOutput("");
            ClientService.generatePdf(data, onSuccess, onFailure);
        },
        onSuccess: (res: GeneratedPdfResponse)=>{
            setOutput(`T&C: ${res.termsAndConditionsPdfUrl}\nPrivacy Policy: ${res.privacyPolicyPdfUrl}`)
        },
        reEnableFormAfterSuccess: true,
    });

    const isMissingValues : boolean = [values["clientName"], values["folderName"], values["contactEmail"]].includes("");

    return <div>
        <Grid container>
            <Grid item xs={6}>
                <form className={classes.root} autoComplete="off" onSubmit={handleFormSubmit}>
                    <div>
                        {formFields.map((field, idx) => {
                            return (
                                <TextField id={field.name + "TextField"} name={field.name} key={idx} label={field.label}
                                           value={values[field.name]}
                                           required={field.isRequired} type="text" fullWidth={true}
                                           onChange={field.type? journeyParamInputHandler[field.type] : handleInputValue}
                                           onBlur={field.type? journeyParamInputHandler[field.type] : handleInputValue}
                                           variant="standard"
                                           {...(errors[field.name] && field.isRequired && {error: true, helperText: errors[field.name]})}/>

                            );
                        })
                        }

                    </div>
                    <br/>
                    <div>
                        <Button id="save" color="primary" variant="contained" startIcon={<SaveIcon/>} type="submit"
                                disabled={!formIsValid() || isMissingValues}>Save</Button>
                    </div>
                </form>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label={"Output"}
                    value={output}
                    multiline
                    fullWidth
                    rows={6}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Grid>
        </Grid>

        <div id='snackbar'>
            <Snackbar open={openSuccessSnackbar} autoHideDuration={5000}>
                <SnackAlert onClose={handleSuccessOnClose} severity={"success"}>
                    Successfully generated PDF and published them on S3
                </SnackAlert>
            </Snackbar>
            <Snackbar open={openFailureSnackbar} autoHideDuration={5000}>
                <SnackAlert onClose={handleFailureOnClose} severity={"error"}>
                    Could not generate and publish PDF
                </SnackAlert>
            </Snackbar>
        </div>
    </div>
}

export default GeneratePdfForm;
