import React, {useState, useRef} from 'react';

import ClientService from "../../services/ClientService";
import {useFormControls} from "../common/FormControls";

import {Button, Snackbar, TextField, Theme, Alert as SnackAlert, Grid} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {createStyles, makeStyles} from "@mui/styles";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: 8,
                width: '95%',
            },
        },
        start_date: {
            width: '48%', paddingRight: '1%'
        }
        ,
        end_date: {
            width: '48%', paddingLeft: '0%'
        }
    })
);


const initialFormValues = {
    "clientName": "",
    "folderName" : "",
    "contactEmail" : "",
    "candidatePolicyUrl": "",
    "employeePolicyUrl": ""
}

const formFields = [
    {
        name: 'clientName',
        label: 'Folder Name (S3 Folder Path)',
        isRequired: true,
    },
]

const UploadClientLogoForm = () => {
    const classes = useStyles();
    const [output, setOutput] = useState<string>("");
    const [logoFile, setLogoFile] = useState<File | undefined>(undefined);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const {
        handleInputValue,
        handleFormSubmit,
        formIsValid,
        errors,
        openSuccessSnackbar,
        openFailureSnackbar,
        handleSuccessOnClose,
        handleFailureOnClose,
        values
    } = useFormControls({
        initialFormValues: initialFormValues,
        formAction: ({data, onSuccess, onFailure})=>{
            if (logoFile) {
                setOutput("");
                ClientService.uploadMedia(logoFile, data["clientName"], onSuccess, onFailure);
            }
        },
        onSuccess: (res: string)=>{
            setOutput(res)
        },
        reEnableFormAfterSuccess: true,
    });

    const isMissingValues : boolean = values["clientName"] === "" || !logoFile;

    return <div>
        <Grid container>
            <Grid item xs={6}>
                <form className={classes.root} autoComplete="off" onSubmit={handleFormSubmit}>
                    <div>
                        {formFields.map((field, idx) => {
                            return (
                                <TextField id={field.name + "TextField"} name={field.name} key={idx} label={field.label}
                                           value={values[field.name]}
                                           required={field.isRequired} type="text" fullWidth={true}
                                           onChange={handleInputValue} onBlur={handleInputValue} variant="standard"
                                           {...(errors[field.name] && field.isRequired && {error: true, helperText: errors[field.name]})}/>

                            );
                        })
                        }

                    </div>
                    <div>
                        <TextField
                            label={"Select a file to upload"}
                            variant="standard"
                            type="text"
                            fullWidth
                            required
                            onClick={(event)=>{
                                fileInputRef.current?.click();
                            }}
                            value={logoFile?.name || ""}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <IconButton component="label" onClick={(event: any)=>{event.stopPropagation();}}>
                                        <FileUploadIcon />
                                        <input
                                            ref={fileInputRef}
                                            style={{display:"none"}}
                                            type="file"
                                            hidden
                                            onChange={(event)=>{
                                                if (event.target.files?.[0]) {
                                                    setLogoFile(event.target.files[0]);
                                                }
                                            }}
                                            name="[licenseFile]"
                                        />
                                    </IconButton>
                                ),
                            }}
                        />
                    </div>
                    <br/>
                    <div>
                        <Button id="save" color="primary" variant="contained" startIcon={<SaveIcon/>} type="submit"
                                disabled={!formIsValid() || isMissingValues}>Save</Button>
                    </div>
                </form>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label={"Output"}
                    value={output}
                    multiline
                    fullWidth
                    rows={4}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Grid>
        </Grid>

        <div id='snackbar'>
            <Snackbar open={openSuccessSnackbar} autoHideDuration={5000}>
                <SnackAlert onClose={handleSuccessOnClose} severity={"success"}>
                    Successfully published logo on S3
                </SnackAlert>
            </Snackbar>
            <Snackbar open={openFailureSnackbar} autoHideDuration={5000}>
                <SnackAlert onClose={handleFailureOnClose} severity={"error"}>
                    Could not upload logo
                </SnackAlert>
            </Snackbar>
        </div>
    </div>
}

export default UploadClientLogoForm;
