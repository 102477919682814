import React from 'react';
import HeaderBar from "../../components/HeaderBar";
import {Card, Grid} from "@mui/material";
import GeneratePdfForm from "../../components/GeneratePdfForm";
import UploadClientLogoForm from "../../components/UploadClientLogoForm";

const MediaTools = () => {
    return <header>
        <HeaderBar/>
        <Grid container style={{width: "100%", padding: 10}} spacing={2}>
            <Grid item xs={12}>
                <Card sx={{padding: 1}} raised>
                    <h3>Generate T&C and Privacy Policy PDF for RTW Client</h3>
                    <GeneratePdfForm/>
                </Card>

            </Grid>

            <Grid item xs={12}>
                <Card sx={{padding: 1}} raised>
                    <h3>Upload and Publish Client Logo</h3>
                    <UploadClientLogoForm/>
                </Card>

            </Grid>
        </Grid>
    </header>
}

export default MediaTools;
