import React, {useContext, useEffect, useState} from "react";

import {Country, JourneyResponse} from "../../models/JourneyResponse";
import {useFormControls} from "../common/FormControls";
import ClientService from "../../services/ClientService";

import {Grid, TextField, Button, Snackbar, Theme, Alert as SnackAlert, createFilterOptions, Autocomplete, Box, InputLabel, Select, MenuItem} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import {createStyles, makeStyles} from "@mui/styles";
import NotificationContext from "../../context/NotificationContext";
import CreateLocation from "../common/CreateLocations";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 8,
            width: '95%',
        },
        start_date: {
            width: '50%', paddingRight: '1%'
        }
        ,
        end_date: {
            width: '50%', paddingLeft: '1%'
        }
    })
);

const countries: readonly Country[] = Object.keys(Country).map(key => Country[key as keyof typeof Country]);

const UpdateJourneyPropertiesForm = ({
                               journey, onUpdated
                           }: { journey: JourneyResponse | undefined, onUpdated: () => void }) => {
    const classes = useStyles();
    const [journeyTypes, setJourneyTypes] = useState<string[]>([]);
    const {logError} = useContext(NotificationContext);

    const {
        handleInputValue,
        handleFormSubmit,
        setMultipleInputValues,
        errors,
        openSuccessSnackbar,
        openFailureSnackbar,
        handleSuccessOnClose,
        handleFailureOnClose,
        formIsValid,
        values
    } = useFormControls({
        initialFormValues: {},
        formAction: ({data, onSuccess, onFailure}) => {
            const caseTtl = parseInt(data.caseTtl);
            const faceCompareThreshold = parseFloat(data.faceCompareThreshold);

            const request = {
                journeyName: data.journeyName,
                country: data.country,
                journeyType: data.journeyType,
                caseTtl: isNaN(caseTtl) || caseTtl < 1 ? undefined : caseTtl,
                faceCompareThreshold: isNaN(faceCompareThreshold)? undefined : faceCompareThreshold,
                locations: data.locations,
            };

            ClientService.updateJourneyProperties(request, journey!.id, onSuccess, onFailure);
        },
        onSuccess: onUpdated
    });

    useEffect(() => {
        if (journey) {
            setMultipleInputValues(journey)
        }
        // eslint-disable-next-line
    }, [journey])

    useEffect(() => {
        ClientService.getJourneyTypes()
            .then(types => setJourneyTypes(types))
            .catch(e => logError("Failed to load journey type"));
    }, [logError])

    
    const getCode = (country: string): string => {
        const  code = Object.keys(Country).find(key => country === Country[key as keyof typeof Country]);
        return  code? code : '';
    }

    const countryFilterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (country: Country) => {
            return getCode(country) + country;
        }
    });

    return <Grid container>
        {
            journey && values?.country && journeyTypes.length > 0 && <form className={classes.root} autoComplete="off" onSubmit={handleFormSubmit}>
                <Grid item xs={6}>
                    <TextField name={"journeyName"} label={"Journey Name"}
                        value={values["journeyName"]}
                        required={true} type="text" fullWidth={true}
                        onChange={handleInputValue} onBlur={handleInputValue}
                        {...(errors["journeyName"] && {error: true, helperText: errors["journeyName"]})}/>
                </Grid>
                <br/>
                <Grid item xs={6}>
                    <Autocomplete
                        id="countryAutocomplete"
                        options={countries}
                        autoHighlight
                        getOptionLabel={(country) => getCode(country)}
                        filterOptions={countryFilterOptions}
                        isOptionEqualToValue={(option, value) => option === value}
                        value={Country[values.country as keyof typeof Country]}
                        disableClearable
                        renderOption={(props, option) => (
                            <Box component="li"  {...props}>
                                {getCode(option)} - {option}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Country" name="country"
                                        onChange={handleInputValue} onBlur={handleInputValue}
                                        required={true} type="text" fullWidth={true}
                                        {...(errors["country"] && {error: true, helperText: errors["country"]})}/>

                        )}
                    />
                </Grid>
                <br/>
                <Grid item xs={6}>
                    <TextField name={"caseTtl"} label={"Case Time-To-Live (number of days)"}
                        value={values.caseTtl || ''}
                        required={false} type="number" fullWidth={true}
                        onChange={handleInputValue} onBlur={handleInputValue}
                        />
                </Grid>
                <br/>
                <Grid item xs={6}>
                    <TextField name={"faceCompareThreshold"} label={"Face compare threshold"}
                               id="id-faceCompareThreshold"
                               value={values.faceCompareThreshold || ''}
                               InputProps={{ inputProps: {step: 'any'} }}
                               required={false} type="number" fullWidth={true}
                               onChange={handleInputValue} onBlur={handleInputValue}
                    />
                </Grid>
                <br/>
                <Grid item xs={6}>
                    <InputLabel id="type-selector-label">Journey Type *</InputLabel>
                    <Select
                        fullWidth={true}
                        labelId="type-selector-label"
                        id="type-selector"
                        label="Journey Type"
                        name={"journeyType"}
                        value={values.journeyType}
                        onChange={handleInputValue}
                        onBlur={handleInputValue}
                    >
                        {journeyTypes.map(journeyType => (<MenuItem value={journeyType}>{journeyType}</MenuItem>))}
                    </Select>
                </Grid>
                <Grid item xs={8}>
                    <CreateLocation locations={values?.locations} onChange={handleInputValue}></CreateLocation>
                </Grid>
                <br/>
                <div>
                    <Button id="save" color="primary" variant="contained" startIcon={<SaveIcon/>} type="submit"
                            disabled={!journey || !formIsValid()}>Save</Button>
                </div>
            </form>
        }
        <div id='snackbar'>
            <Snackbar open={openSuccessSnackbar} autoHideDuration={5000}>
                <SnackAlert onClose={handleSuccessOnClose} severity={"success"}>
                    Successfully updated journey {journey?.journeyName}
                </SnackAlert>
            </Snackbar>
            <Snackbar open={openFailureSnackbar} autoHideDuration={5000}>
                <SnackAlert onClose={handleFailureOnClose} severity={"error"}>
                    Could not update journey
                </SnackAlert>
            </Snackbar>
        </div>
    </Grid>
}


export default UpdateJourneyPropertiesForm;
