import {useParams} from "react-router-dom";
import HeaderBar from "../../components/HeaderBar";
import {Breadcrumbs, Container, Link, Typography} from "@mui/material";
import CreateBrandForm from "../../components/CreateBrandForm";
import React, {useContext, useEffect, useState} from "react";
import {BrandResponse} from "../../models/BrandResponse";
import ClientService from "../../services/ClientService";
import NotificationContext from "../../context/NotificationContext";

const UpdateBrand = () => {
    const {clientId, brandId} = useParams();
    const {logError} = useContext(NotificationContext);

    const [brandDetails, setBrandDetails] = useState<BrandResponse | undefined>(undefined);

    useEffect(() => {
        ClientService.getBrandById(clientId, brandId).then(response => {
            setBrandDetails(response.data);
        }).catch(e => logError("failed to get brand details"));
    }, [clientId, brandId, logError])

    return <header>
        <HeaderBar/>
        <Container maxWidth={"xl"}>
            <Breadcrumbs aria-label="breadcrumb" id="breadcrumb">
                <Link color="inherit" href="/">
                    EMS Management
                </Link>
                <Link color="inherit" href="/clients">
                    Clients
                </Link>
                <Link color="inherit" href={"/clients/" + clientId}>
                    {clientId}
                </Link>
                <Typography color="inherit">
                    Brands
                </Typography>
                <Link color="inherit" href={"/clients/" + clientId + "/brands/" + brandId}>
                    {brandId}
                </Link>
                <Typography color="inherit">
                    Edit
                </Typography>
            </Breadcrumbs>
            <h1 id="title">Edit Brand Details</h1>
            {
                brandDetails && <CreateBrandForm clientId={clientId} updateTarget={brandDetails}/>
            }
        </Container>
    </header>
}

export default UpdateBrand;
