import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import Routes from "./routes";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {BrowserRouter as Router} from 'react-router-dom';
import {NotificationProvider} from "./context/NotificationContext";

const theme =
    createTheme({
            palette: {
                primary: {
                    main: '#0AC1DD'
                },
                secondary: {
                    main: '#FFEE03'
                }
            },
            typography: {
                fontFamily: '"Titillium Web", sans-serif;'
            }
        }
    );

const container = document.getElementById('root') as Element;
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <NotificationProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Router>
                    <Routes/>
                </Router>
            </ThemeProvider>
        </NotificationProvider>
    </React.StrictMode>
);
