import HeaderBar from "../../components/HeaderBar";
import {Breadcrumbs, Container, Link, Typography} from "@mui/material";
import React, {useState, useEffect} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import ClientService from "../../services/ClientService";
import {JourneyResponse} from "../../models/JourneyResponse";
import UpdateJourneyPropertiesForm from "../../components/UpdateJourneyPropertiesForm";

const UpdateJourneyProperties = () => {
    const {clientId, brandId, journeyId} = useParams();
    const navigate = useNavigate();

    const [journey, setJourney] = useState<JourneyResponse | undefined>(undefined);

    const handleReturnToBrandPageAfterUpdated = ()=> {
        navigate(`/clients/${clientId}/brands/${brandId}`)
    }

    useEffect(()=>{
        ClientService.getJourneys(clientId, brandId)
            .then(response => {
                const journeyFound = response.data.find(journey => journey.id === journeyId);
                if (journeyFound) {
                    setJourney(journeyFound);
                }
            })
    }, [clientId, brandId, journeyId])

    return <header>
        <HeaderBar/>
        <Container maxWidth={"xl"}>
            <Breadcrumbs aria-label="breadcrumb" id="breadcrumb">
                <Link color="inherit" href="/">
                    EMS Management
                </Link>
                <Link color="inherit" href="/clients">
                    Clients
                </Link>
                <Link color="inherit" href={"/clients/" + clientId}>
                    {clientId}
                </Link>
                <Typography color="inherit">
                    Brands
                </Typography>
                <Link color="inherit" href={"/clients/" + clientId + "/brands/" + brandId}>
                    {brandId}
                </Link>
                <Typography color="inherit">
                    Journeys
                </Typography>
                <Typography color="textPrimary">{journeyId}</Typography>
            </Breadcrumbs>
            <h1 id="title">Update Journey</h1>

            <UpdateJourneyPropertiesForm journey={journey}
                               onUpdated={handleReturnToBrandPageAfterUpdated}/>

        </Container>
    </header>
}

export default UpdateJourneyProperties;