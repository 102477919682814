import React, {useMemo, useState, useEffect} from 'react';
import {ClientResponse} from "../../models/ClientResponse";
import VirtualizedTable from "../../components/common/VirtualizedTable";
import Paginator from "../../components/common/Paginator";
import {IndexRange, OverscanIndexRange} from "react-virtualized";
import {TextField} from "@mui/material";

const AllClientsList = ({clients}: {clients: ClientResponse[]}) => {

    const [pageCount, setPageCount] = useState<number>(1);
    const [page, setPage] = useState<number>(1);
    const [scrollToIndex, setScrollToIndex] = useState<number | undefined> (undefined);
    const [perPage, setPerPage] = useState<number>(1);
    const [searchString, setSearchString] = useState<string>("");

    const clientsFiltered = useMemo(()=>{
        return clients.filter(client => searchString.length === 0
            || client.name.toUpperCase().includes(searchString.toUpperCase())
            || client.contactName.toUpperCase().includes(searchString.toUpperCase())
            || client.contactEmail.toUpperCase().includes(searchString.toUpperCase())
            || client.contactPhoneNumber.toUpperCase().includes(searchString.toUpperCase())
        )
    },[clients, searchString]);

    const setPageHandler = (pageClicked : number) => {
        setScrollToIndex( (pageClicked - 1) * perPage );
        setPage(pageClicked);
    }

    const scrollTableHandler = (info: IndexRange & OverscanIndexRange) => {
        setPageCount( Math.ceil(clientsFiltered.length / perPage) ) ;
        setScrollToIndex(undefined);
        setPage(Math.ceil(info.stopIndex / perPage));
    }

    const searchInputHandler = (event: any) => {
        setSearchString(event.target.value);
    }

    useEffect(()=>{
        // trigger onScroll event, so we can get scroll size to calculate number of pages
        setScrollToIndex(1);
        setTimeout(()=>{
            setScrollToIndex(0);
        },100)
    },[clients])

    const isActive = (client: ClientResponse) => {
        let currentDate = new Date();
        return new Date(client.startDate) <= currentDate && new Date(client.endDate) >= currentDate;
    };

    return <>
        <div style={{width: '1200px', alignSelf: 'flex-start', margin: 'auto'}}>
            <TextField
                id={"clientSearchString"}
                sx={{maxWidth: 400, zoom: 0.8}}
                label={"Search"}
                value={searchString}
                onChange={searchInputHandler}
                fullWidth={true}
                variant="outlined"
            />
        </div>
        <Paginator currentPage={page} pageCount={pageCount}  onPageChange={setPageHandler}/>
        <div style={{height: '63vh', width: '1200px', alignSelf: 'center', margin: 'auto'}}>
            <VirtualizedTable
                onScroll={(info)=>{ setPerPage( Math.floor(clientsFiltered.length / (info.scrollHeight / info.clientHeight) )) }}
                rowHeight={40}
                rowCount={clientsFiltered.length}
                rowGetter={({ index }) => clientsFiltered[index]}
                scrollToIndex={scrollToIndex}
                onRowsRendered={scrollTableHandler}
                columns={[
                    {
                        width: 200,
                        label: 'Name',
                        dataKey: 'name',
                        urlGetter: ({ rowData }) => { return { label: rowData.name, url: `/clients/${rowData.id}`} }
                    },
                    {
                        width: 250,
                        label: 'Contact Name',
                        dataKey: 'contactName',
                    },
                    {
                        width: 250,
                        label: 'Contact Email',
                        dataKey: 'contactEmail',
                    },
                    {
                        width: 300,
                        label: 'Contact Telephone Number',
                        dataKey: 'contactPhoneNumber',
                    },
                    {
                        width: 200,
                        label: 'Active',
                        dataKey: 'endDate',
                        dataGetter: ({ rowData }) => { return isActive(rowData)? "✅" : "❌"; }
                    },
                ]}
            />
        </div>
    </>;
};


export default AllClientsList;
