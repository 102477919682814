import React, {createContext, useCallback, useState} from "react";
import {Snackbar, Alert} from "@mui/material";

interface NotificationContextInterface {
    logInfo : (message: string) => void;
    logError : (message: string) => void;
}

const NotificationContext = createContext<NotificationContextInterface>({
    logInfo: ()=>{},
    logError: ()=>{},
});

export const NotificationProvider = ({children} : {children: React.ReactNode}) => {
    const [infoMessage, setInfoMessage] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const logInfo = useCallback((message: string) => {
        setInfoMessage(message);
        console.log(message);
    }, [setInfoMessage]);

    const logError = useCallback((message: string) => {
        setErrorMessage(message);
        console.error(message);
    }, [setErrorMessage]);

    const closeInfoSnackBarHandler = () => setInfoMessage(undefined);
    const closeErrorSnackBarHandler = () => setErrorMessage(undefined);

    return (
        <NotificationContext.Provider value={{
            logInfo,
            logError,
        }}>
            { children }
            <div id='snackbar'>
                <Snackbar open={!!infoMessage} autoHideDuration={5000} onClose={closeInfoSnackBarHandler}>
                    <Alert onClose={closeInfoSnackBarHandler} severity={"success"} elevation={6} variant="filled">
                        { infoMessage }
                    </Alert>
                </Snackbar>
                <Snackbar open={!!errorMessage} autoHideDuration={5000} onClose={closeErrorSnackBarHandler}>
                    <Alert onClose={closeErrorSnackBarHandler} severity={"error"} elevation={6} variant="filled">
                        { errorMessage }
                    </Alert>
                </Snackbar>
            </div>
        </NotificationContext.Provider>
    );
}
export default NotificationContext;
