import React from 'react'
import {Button, ButtonGroup} from "@mui/material";


const BetweenButton = ({key}: { key: string }) => <Button key={key} style={{cursor: 'default'}}>...</Button>

const Paginator = (
        {currentPage, onPageChange, range = 3, pageCount}:
        {currentPage: number, onPageChange: (page: number) => void, range?: number, pageCount: number}
    ) => {
    const renderedPages: number[] = Array.from(Array(range * 2 + 1).keys())
        .map(i => currentPage - range + i)
        .filter(i => i > 0 && i <= pageCount)

    const showStart = currentPage - range > 1
    const showEnd = currentPage + range < pageCount

    return ( pageCount <= 1 ? <></> :
        <ButtonGroup variant="outlined" aria-label="Pagination Buttons">
            {showStart && (
                [
                    <Button key='button-p-start' onClick={() => onPageChange(1)}>1</Button>,
                    <BetweenButton key={"button-dots-left"}/>
                ]
            )}
            {renderedPages.map(page => (
                <Button
                    key={`button-p${page}`}
                    onClick={() => onPageChange(page)}
                    variant={currentPage === page ? "contained" : "outlined"}
                >
                    {page}
                </Button>
            ))}
            {showEnd && (
                [
                    <BetweenButton key={"button-dots-right"}/>,
                    <Button key='button-p-end' onClick={() => onPageChange(pageCount)}>{pageCount}</Button>
                ]
            )}
        </ButtonGroup>
    )
}

export default Paginator;
