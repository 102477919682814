import React from "react";

import {ConfigService} from "../../services/ConfigService/ConfigService";
import AuthenticationService from "../../services/AuthenticationService/AuthenticationService";

import AppBar from '@mui/material/AppBar';
import { Toolbar } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    list: {
        width: 250,
    },
    menuTitle: {
        padding: 15,
        fontSize: 20,
    }
}));

const COGNITO_URL = ConfigService.getConfigValue("REACT_APP_COGNITO_URL");
const CLIENT_ID = ConfigService.getConfigValue("REACT_APP_CLIENT_ID");
const REDIRECT_URL = ConfigService.getConfigValue("REACT_APP_EMS_MANAGEMENT_UI_URL");
const URL = `${COGNITO_URL}/login?client_id=${CLIENT_ID}&response_type=token&scope=email+openid+profile&redirect_uri=${REDIRECT_URL}/login`;


const HeaderBar = () => {
    const classes = useStyles();

    const [isOpen, setOpen] = React.useState(false)

    const pageList = [
        {title: 'Clients', icon: <PeopleAltIcon/>, link: 'clients'},
        {title: 'Media Tools', icon: <PermMediaIcon/>, link: 'media'},
        {title: 'Case Query', icon: <PermMediaIcon/>, link: 'cases/query'},
        ...(AuthenticationService.isAdmin() ? [{title: 'Service Status', icon: <SettingsOutlinedIcon/>, link: 'status'}] : [])
    ]

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent,) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setOpen(open)
    };

    const menuList = () => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}>
            <div className={classes.menuTitle}>EMS Management</div>

            <List>
                {pageList.map((page, index) => (
                    <ListItem id={page.link} button key={page.title} component="a" href={"/" + page.link}>
                        <ListItemIcon>{page.icon}</ListItemIcon>
                        <ListItemText primary={page.title}/>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return <AppBar position="static" id="header-bar">
        <Toolbar>
            <React.Fragment>
                <IconButton id='burgerMenu' edge="start" color="inherit" aria-label="menu"
                            onClick={toggleDrawer(true)}>
                    <MenuIcon/>
                </IconButton>
                <Drawer anchor='left' open={isOpen} onClose={toggleDrawer(false)}>
                    {menuList()}
                </Drawer>
            </React.Fragment>
            <Typography id='ems-management-title' variant="h6" className={classes.title}>
                EMS Management
            </Typography>
            <Button id="login-button" color="inherit"
                    href={`${URL}`}>{AuthenticationService.isAuthenticated() ? "Logout" : "Login"}</Button>
        </Toolbar>
    </AppBar>;
}
export default HeaderBar;
