import React from 'react';
import {Navigate} from "react-router-dom";
import LocalStorageService from "../../services/LocalStorageService/LocalStorageService";

const Authorization = () => {
    let queryString = window.location.search || window.location.hash;
    if (queryString.charAt(0) === '?' || queryString.charAt(0) === '#') {
        queryString = queryString.substring(1);
    }

    const urlParams = new URLSearchParams(queryString);
    const id_token = urlParams.get('id_token')
    const ttl = urlParams.get('expires_in');

    if (id_token && ttl) {
        const timeToLiveSeconds = 1000 * Number.parseInt(ttl);
        LocalStorageService.storeToken(id_token, timeToLiveSeconds);
        return (<Navigate to="/clients" replace />)
    }
}

export default Authorization;
