import { useCallback } from 'react';
import { useStore, getBezierPath } from 'reactflow';
import { getEdgeParams } from './utils';
import "./edgeLabel.css"

function FloatingEdge({ id, source, target, markerEnd, style, data, labelStyle }: {
    id: string,
    source: string,
    target: string,
    markerEnd?: any,
    style?: any,
    data?: any,
    labelStyle?: any,
}) {
    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));
    const onClickHandler = useCallback(()=>{
        if (data["onLabelClick"] && typeof data["onLabelClick"] === "function") {
            data["onLabelClick"]();
        }
    },[data]);

    if (!sourceNode || !targetNode) {
        return null;
    }

    const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

    const [edgePath] = getBezierPath({
        sourceX: sx,
        sourceY: sy,
        sourcePosition: sourcePos,
        targetPosition: targetPos,
        targetX: tx,
        targetY: ty,
    });

    return (
        <>
            <path
                id={id}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
                style={style}
            />
            <foreignObject
                x={Math.min(sx,tx)}
                y={Math.min(sy,ty)}
                style={{
                    background: "transparent",
                    width: Math.abs(sx-tx),
                    height: Math.abs(sy-ty),
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "visible"
            }}>
                <body style={{
                    background: "transparent",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    overflow: "visible"
                }}>
                    <div
                        className={"edgeLabel"}
                        onClick={onClickHandler}
                        style={{
                        textAlign: "center",
                        zIndex:9999,
                        fontSize:"65%",
                        border:"1px solid",
                        paddingLeft:2,
                        paddingRight:2,
                        width:"auto",
                        minHeight:4,
                        background: "white",
                        transform: `translate(${(sx-tx)*0.2}px,${(sy-ty)*0.2}px)`,
                        ...labelStyle}}>{data.label}</div>
                </body>
            </foreignObject>
        </>
    );
}

export default FloatingEdge;
