import React from 'react';
import {useNavigate} from 'react-router-dom';

import ClientService from "../../services/ClientService";
import {useFormControls} from "../common/FormControls";

import {Grid, Button, Snackbar, TextField, Theme, Alert as SnackAlert} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SaveIcon from '@mui/icons-material/Save';
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: 8,
                width: '95%',
            },
        },
        start_date: {
            width: '48%', paddingRight: '1%'
        }
        ,
        end_date: {
            width: '48%', paddingLeft: '0%'
        }
    })
);


const initialFormValues = {
    name: "",
    formSubmitted: false,
    success: false,
    startDate: new Date().toISOString().substring(0,10) + "T00:00:00+00:00",
    endDate: new Date().toISOString().substring(0,10) + "T00:00:00+00:00",
    allowedEmailDomains: ""
}

const formFields = [
    {
        name: 'name',
        label: 'Client Name',
    },
    {
        name: 'contactName',
        label: 'Contact Name'
    },
    {
        name: 'contactEmail',
        label: 'Contact Email'
    },
    {
        name: 'contactPhoneNumber',
        label: 'Contact Phone Number'
    },
    {
        name: 'allowedEmailDomains',
        label: 'Allowed Email Domains'
    }
]

const CreateClientForm = ({id}: { id: string; }) => {
    const classes = useStyles();
    const [selectedStartDate, setSelectedStartDate] = React.useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());

    const navigate = useNavigate();

    const handleStartDateChange = (date: any) => {
        setSelectedStartDate(date);
        handleInputValue({target: {name: "startDate", value: date.toISOString().substring(0,10) + "T00:00:00+00:00"}});
    };

    const handleEndDateChange = (date: any) => {
        setSelectedEndDate(date);
        handleInputValue({target: {name: "endDate", value: date.toISOString().substring(0,10) + "T00:00:00+00:00"}});
    };

    const {
        handleInputValue,
        handleFormSubmit,
        formIsValid,
        errors,
        openSuccessSnackbar,
        openFailureSnackbar,
        handleSuccessOnClose,
        handleFailureOnClose,
        values
    } = useFormControls({
        initialFormValues: initialFormValues,
        formAction: ({data, onSuccess, onFailure})=>{
            ClientService.createClient(data, onSuccess, onFailure);
        },
        onSuccess: ()=>{
            navigate('/clients');
        }
    });
    return <div id={id}>
        <form data-testid="createclient" className={classes.root} autoComplete="off" onSubmit={handleFormSubmit}>
            <div>
                {formFields.map((field, idx) => {
                    return (
                        <TextField id={field.name + "TextField"} name={field.name} key={idx} label={field.label}
                                   required={true} type="text" fullWidth={true}
                                   onChange={handleInputValue} onBlur={handleInputValue} variant="standard"
                                   {...(errors[field.name] && {error: true, helperText: errors[field.name]})}/>

                    );
                })
                }
            </div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container justifyContent="space-between">
                    <DatePicker className={classes.start_date}
                                slotProps={{ textField: { variant: 'standard', required: true, id: "startDate", name: "startDate" } }}
                                label="Start Date"
                                format={"yyyy-MM-dd"}
                                value={selectedStartDate}
                                onChange={handleStartDateChange}
                    />
                    <DatePicker className={classes.end_date}
                                slotProps={{ textField: { variant: 'standard', required: true, id: "endDate", name: "endDate" } }}
                                label="End Date"
                                format={"yyyy-MM-dd"}
                                value={selectedEndDate}
                                onChange={handleEndDateChange}
                    />
                </Grid>
            </LocalizationProvider>
            <br/>
            <div>
                <Button id="save" color="primary" variant="contained" startIcon={<SaveIcon/>} type="submit"
                        disabled={!formIsValid()}>Save</Button>
            </div>
        </form>
        <div id='snackbar'>
            <Snackbar open={openSuccessSnackbar} autoHideDuration={5000}>
                <SnackAlert onClose={handleSuccessOnClose} severity={"success"}>
                    Successfully created client {values.name}
                </SnackAlert>
            </Snackbar>
            <Snackbar open={openFailureSnackbar} autoHideDuration={5000}>
                <SnackAlert onClose={handleFailureOnClose} severity={"error"}>
                    Could not create client
                </SnackAlert>
            </Snackbar>
        </div>
    </div>
}

export default CreateClientForm;
