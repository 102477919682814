import {ConfigService} from "./ConfigService/ConfigService";
import axios from "axios";
import LocalStorageService from "./LocalStorageService/LocalStorageService";
import {DocumentTypeResponse} from "../models/DocumentTypeResponse";


class DocumentTypeService {
    documentTypesEndpoint: string = ConfigService.getConfigValue('REACT_APP_DOCUMENT_TYPES_ENDPOINT');

    getDocumentTypes = () => {
        console.log("Getting document types")
        return axios.get<DocumentTypeResponse[]>(this.documentTypesEndpoint, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            }
        });
    }
}

const documentTypeServiceSingleton = new DocumentTypeService();
export default documentTypeServiceSingleton;
