import React from 'react';
import HeaderBar from "../../components/HeaderBar";

const Home = () => {
    return <header>
        <HeaderBar/>
    </header>
}

export default Home;
