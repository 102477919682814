import axios from "axios";
import {ConfigService} from "./ConfigService/ConfigService";
import LocalStorageService from "./LocalStorageService/LocalStorageService";

class CasesService {
    casesEndpoint: string = ConfigService.getConfigValue('REACT_APP_CASES_ENDPOINT');

    archiveCases = (caseIds: String, success: Function, error: Function) => {
        console.log(`Archiving cases: ${caseIds}`);
        const url = this.casesEndpoint + '/' + caseIds + '/archive';
        axios.post(url, null,{
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            }
        }).then(res => {
            success(res)
        }).catch(err => {
            error(err);
        });
    }

    searchCases = async (queryString: string) => {
        console.log(`Searching cases: ${queryString}`);
        const url = this.casesEndpoint + '/search/' + queryString;
        const res = await axios.get(url, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            }
        });
        return res.data;
    }
}

const clientServiceSingleton = new CasesService();
export default clientServiceSingleton;
