import React, {useState} from 'react';

import CaseService from "../../services/CaseService";

import HeaderBar from "../../components/HeaderBar";
import AlertDialog from "../../components/common/AlertDialog";
import AlertWarning from "../../components/common/AlertWarning";

import {Grid, TextField, Breadcrumbs, Container, Link, Typography, Card, CardContent, Snackbar, Alert as SnackAlert} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';

export const useFormControls = () => {
    const [cases, setCases] = useState<String[]>([""]);
    const [loading, setLoading] = useState(false);
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openWarningAlert, setOpenWarningAlert] = useState(false);

    const handleInputValue = (e: any) => {
        cases[e.target.dataset.id] = e.target.value;
        setCases([...cases]);
        addNewCaseTextInput(e);
    };

    const getNotEmptyCaseIds = (values: String[] = cases)  => values.filter(id => id.replace(/\s/g, '')  !== "");
    const getEmptyValues = (values: String[]  = cases)  => values.filter(id => id.replace(/\s/g, '')  === "");

    const addNewCaseTextInput = (e: any) => {
        if (getEmptyValues().length === 0) {
            setCases([...cases, ""]);
        }
    };

    const handleBtnArchiveClick = () => {
        if (getNotEmptyCaseIds().length >= 1) {
            setOpenConfirmDialog(true);
        } else {
            setOpenWarningAlert(true);
        }
    };

    const handleArchiveCasesAgreed = () => {
        if (getNotEmptyCaseIds().length >= 1) {
            setLoading(true);
            CaseService.archiveCases(getNotEmptyCaseIds().join(","),
                handleCasesArchivationSuccess, handleCasesArchivationError);
        }
        handleCloseConfirmDialog();
    };

    const handleArchiveCasesDisagreed = () => {
        handleCloseConfirmDialog();
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    };

    const handleCloseWarningAlert = () => {
        setOpenWarningAlert(false);
    };

    const handleCasesArchivationSuccess = (res: any) => {
        if (res) {
            setOpenSuccessSnackbar(true);
            setLoading(false);
            setCases([""]);
        }
    }

    const handleCasesArchivationError = (err: any) => {
        if (err) {
            setOpenFailureSnackbar(true);
            setLoading(false);
        }
    }

    const handleSuccessOnClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessSnackbar(false);
    }
    const handleFailureOnClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenFailureSnackbar(false);
    }

    return {
        handleInputValue,
        getNotEmptyCaseIds,
        openConfirmDialog,
        openWarningAlert,
        handleCloseConfirmDialog,
        handleArchiveCasesAgreed,
        handleArchiveCasesDisagreed,
        handleBtnArchiveClick,
        handleCloseWarningAlert,
        loading,
        cases,
        openSuccessSnackbar,
        openFailureSnackbar,
        handleSuccessOnClose,
        handleFailureOnClose,
    };
}

const ArchiveCases = () => {
    const {
        handleInputValue,
        getNotEmptyCaseIds,
        openConfirmDialog,
        openWarningAlert,
        handleCloseConfirmDialog,
        handleArchiveCasesAgreed,
        handleArchiveCasesDisagreed,
        handleBtnArchiveClick,
        handleCloseWarningAlert,
        loading,
        cases,
        openSuccessSnackbar,
        openFailureSnackbar,
        handleSuccessOnClose,
        handleFailureOnClose,
    } = useFormControls();

    return <header>
        <HeaderBar/>
        <Breadcrumbs aria-label="breadcrumb" id="breadcrumb" style={{paddingLeft: '24px', paddingRight: '24px'}}>
            <Link color="inherit" href="/">
                EMS Management
            </Link>
            <Typography color="textPrimary">Cases</Typography>
        </Breadcrumbs>
        <Container maxWidth={"xl"}>
            <Card style={{maxWidth: '50%', marginTop: '10px'}}>
                <CardContent  style={{margin: 'auto'}}>
            <h1 id="title">Archive cases</h1>
            <form id='archiveCases' autoComplete="off">
                <Grid container item xs={12} spacing={1} style={{maxHeight: '640px', overflow:'auto'}}> {
                    cases.map((field, idx) => {
                        return  <Grid item xs={12} key={idx}>
                                    <TextField id={idx + "-caseId"}
                                               value={field}
                                               label="case id"
                                               inputProps={{
                                                   'data-id': idx
                                               }}
                                               fullWidth={true}
                                               key={idx + "-caseId"}
                                               onChange={handleInputValue}/>
                               </Grid>
                        })
                    }
                </Grid>
                    <div style={{marginTop: '15px'}}>
                        <LoadingButton
                            color="primary"
                            onClick={handleBtnArchiveClick}
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<DeleteIcon />}
                            variant="contained">
                            Archive
                        </LoadingButton>
                    </div>
            </form>
                </CardContent>
            </Card>
        </Container>
        <AlertDialog title='Are you sure want to archive the case(s) with next id(s)?'
                     contentText={getNotEmptyCaseIds().join("<br>")}
                     btnAgreeText='yes'
                     btnDisagreeText='no'
                     open={openConfirmDialog}
                     closeBtnAgreeHandler={handleArchiveCasesAgreed}
                     closeBtnDisagreeHandler={handleArchiveCasesDisagreed}
                     closeDialogHandler={handleCloseConfirmDialog}/>
        <Snackbar open={openSuccessSnackbar} autoHideDuration={5000}>
            <SnackAlert elevation={6} variant="filled" onClose={handleSuccessOnClose} severity={"success"}>
                Successfully archived cases
            </SnackAlert>
        </Snackbar>
        <Snackbar open={openFailureSnackbar} autoHideDuration={5000}>
            <SnackAlert elevation={6} variant="filled" onClose={handleFailureOnClose} severity={"error"}>
                Could not archive cases
            </SnackAlert>
        </Snackbar>
        <AlertWarning contentText="Please enter at least one case id"
                      open={openWarningAlert}
                      closeHandler={handleCloseWarningAlert}/>
    </header>
}

export default ArchiveCases;
