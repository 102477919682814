import {format} from 'date-fns'

export class DateUtils {
    static toDisplayDateTime(date: Date | string): string {
        try {
            if (!date) throw new Error("null date value");
            if (date instanceof Date) return format(date, 'yyyy-MM-dd HH:mm');
            return format(new Date(date), 'yyyy-MM-dd HH:mm');
        } catch (e: any) {
            console.warn("received value at DateUtils is invalid", date);
            return date instanceof Date ? date.toString() : (date || ' ');
        }
    }
}
