import React, {useMemo, useState, useEffect} from 'react';
import {BrandResponse} from "../../../models/BrandResponse";
import VirtualizedTable from "../../../components/common/VirtualizedTable";
import Paginator from "../../../components/common/Paginator";
import {IndexRange, OverscanIndexRange} from "react-virtualized";
import {TextField, Typography, Button, Grid} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useNavigate} from "react-router-dom";

const BrandsListView = ({brands, clientId}: {brands: BrandResponse[], clientId: string}) => {

    const [pageCount, setPageCount] = useState<number>(1);
    const [page, setPage] = useState<number>(1);
    const [scrollToIndex, setScrollToIndex] = useState<number | undefined> (undefined);
    const [perPage, setPerPage] = useState<number>(1);
    const [searchString, setSearchString] = useState<string>("");

    const clientsFiltered = useMemo(()=>{
        return brands.filter(brand => searchString.length === 0
            || brand.name.toUpperCase().includes(searchString.toUpperCase())
            || brand.contactName.toUpperCase().includes(searchString.toUpperCase())
            || brand.contactEmail.toUpperCase().includes(searchString.toUpperCase())
            || brand.contactPhoneNumber.toUpperCase().includes(searchString.toUpperCase())
        )
    },[brands, searchString]);

    const setPageHandler = (pageClicked : number) => {
        setScrollToIndex( (pageClicked - 1) * perPage );
        setPage(pageClicked);
    }

    const scrollTableHandler = (info: IndexRange & OverscanIndexRange) => {
        setPageCount( Math.ceil(clientsFiltered.length / perPage) ) ;
        setScrollToIndex(undefined);
        setPage(Math.ceil(info.stopIndex / perPage));
    }

    const searchInputHandler = (event: any) => {
        setSearchString(event.target.value);
    }

    useEffect(()=>{
        // trigger onScroll event, so we can get scroll size to calculate number of pages
        setScrollToIndex(1);
        setTimeout(()=>{
            setScrollToIndex(0);
        },100)
    },[brands])

    const isActive = (brand: BrandResponse) => {
        let currentDate = new Date();
        return new Date(brand.startDate) <= currentDate && new Date(brand.endDate) >= currentDate;
    };

    const navigate = useNavigate();

    const createButtonHandler = () => {
        navigate(`/clients/${clientId}/brands/new`);
    }

    return <>
        <Grid container alignContent={"center"} justifyContent={"space-between"} sx={{marginBottom: 1}}>
            <Typography color={"textSecondary"} align={"left"}>Brands</Typography>
            <Button id="addNewBrand" onClick={createButtonHandler}
                    variant={"contained"} startIcon={<AddIcon/>} sx={{fontSize: 12}}>Create New</Button>
        </Grid>
        <div style={{width: '100%', alignSelf: 'flex-start', margin: 'auto'}}>
            <TextField
                sx={{maxWidth: 400, zoom: 0.8}}
                label={"Search"}
                value={searchString}
                onChange={searchInputHandler}
                fullWidth={true}
                variant="outlined"
            />
        </div>
        <Paginator currentPage={page} pageCount={pageCount}  onPageChange={setPageHandler}/>
        <div style={{height: 230, width: '100%', alignSelf: 'center', margin: 'auto'}}>
            <VirtualizedTable
                onScroll={(info)=>{ setPerPage( Math.floor(clientsFiltered.length / (info.scrollHeight / info.clientHeight) )) }}
                rowHeight={40}
                rowCount={clientsFiltered.length}
                rowGetter={({ index }) => clientsFiltered[index]}
                scrollToIndex={scrollToIndex}
                onRowsRendered={scrollTableHandler}
                columns={[
                    {
                        width: 200,
                        label: 'Name',
                        dataKey: 'name',
                        urlGetter: ({ rowData }) => { return { label: rowData.name, url: `/clients/${clientId}/brands/${rowData.id}`} }
                    },
                    {
                        width: 200,
                        label: 'Active',
                        dataKey: 'endDate',
                        dataGetter: ({ rowData }) => { return isActive(rowData)? "✅" : "❌"; }
                    },
                ]}
            />
        </div>
    </>;
};


export default BrandsListView;
