import React, {useState} from "react";
import { Alert as MuiAlert, AlertProps } from '@mui/material';

export const useFormControls = ({initialFormValues, formAction, onSuccess, reEnableFormAfterSuccess}:
                                    {
                                        initialFormValues: { [key: string]: any },
                                        formAction: ({
                                                         data,
                                                         onSuccess,
                                                         onFailure
                                                     }: { data: any, onSuccess: (res: any) => void, onFailure: (err: any) => void }) => void,
                                        onSuccess: (res? : any) => void,
                                        reEnableFormAfterSuccess? : boolean,
                                    },
) => {

    const [values, setValues] = useState(initialFormValues);
    const [errors, setErrors] = useState({} as any);
    const [uploading, setUploading] = useState<boolean>(false);
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);

    const validate: any = (fieldValues = values) => {
        let temp: any = {...errors}
        for (let key in fieldValues) {
            temp[key] = fieldValues[key] ? null : "This field is required."
        }
        setErrors({
            ...temp
        });
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleInputValue = (e: any) => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value
        });
        validate({[name]: value});
    };

    const createInputValueHandler = (validator : (input: string) => string | null ) => (e: any) => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: validator(e.target.value)
        });
    }

    const setMultipleInputValues = (newValues: any) => {
        setValues({
            ...values,
            ...newValues
        })
    }

    const handleSuccessOnClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessSnackbar(!openSuccessSnackbar);
    }
    const handleFailureOnClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenFailureSnackbar(!openFailureSnackbar);
    }
    const handleCreateSuccess = (res: any) => {
        if (res) {
            setOpenSuccessSnackbar(true);
            onSuccess(res);
            if (reEnableFormAfterSuccess) {
                setUploading(false);
            }
        }
    }
    const handleCreateError = (err: any) => {
        if (err) {
            setOpenFailureSnackbar(true);
            setUploading(false);
        }
    }
    const handleFormSubmit = async (e: any) => {
        e.preventDefault();
        if (formIsValid()) {
            setUploading(true);
            formAction({
                data: values,
                onSuccess: handleCreateSuccess,
                onFailure: handleCreateError
            });
        }
    };
    const formIsValid = (fieldValues = values) => {
        if (uploading) return false;
        const isValid = fieldValues.hasOwnProperty("name") ? fieldValues.name : true;
        Object.values(errors).every((x) => x === "");

        return isValid;
    };

    const journeyParamInputHandler = {
        "Email": createInputValueHandler((input) => {
            if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input)) {
                return null;
            }
            return "Please input a valid email address, eg, recipient@example.com";
        }),
        "URL": createInputValueHandler((input) => {
            if (/https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(input)) {
                return null;
            }
            return "Please input a valid URL, eg, https://icons.s3.amazon.com/file.pdf";
        }),
        "COLOUR": createInputValueHandler((input) => {
            if (/^([A-F0-9]{3}|[A-F0-9]{6})$/i.test(input)) {
                return null;
            }
            return "Please input a valid HTML Colour code (without #), eg, 10FADE, 1FD";
        }),
        "Number": handleInputValue,
        "PlainText": handleInputValue,
        "MultiText": handleInputValue
    }

    const groupBy = (x: any[], f: Function) => x.reduce((a, b, i) => {
        (a[f(b, i, x)] ||= []).push(b);
        return a;
    }, {});

    return {
        handleInputValue,
        handleFormSubmit,
        createInputValueHandler,
        setMultipleInputValues,
        formIsValid,
        errors,
        openSuccessSnackbar,
        openFailureSnackbar,
        handleSuccessOnClose,
        handleFailureOnClose,
        Alert,
        values,
        journeyParamInputHandler,
        groupBy
    };
}
