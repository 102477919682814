import React, {useCallback, useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';

import {IdentityProviderResponse, IdentityProviderType} from "../../models/IdentityProviderResponse";
import ClientService from "../../services/ClientService";
import {SamlResponse} from "../../models/SamlResponse";
import {OpenIdConnectResponse} from "../../models/OpenIdConnectResponse";
import {BrandResponse} from "../../models/BrandResponse";
import {JourneyResponse} from "../../models/JourneyResponse";
import {UserResponse} from "../../models/UserResponse";
import {CreateUserRequest} from "../../models/CreateUserRequest";

import HeaderBar from "../../components/HeaderBar";
import SAMLProviderForm from "../../components/SAMLProviderForm";
import OIDCProviderForm from "../../components/OIDCProviderForm";
import BrandDetailsForm from "../../components/BrandDetailsForm";
import JourneyForm from "../../components/JourneysForm";
import NotificationContext from "../../context/NotificationContext";

import {Breadcrumbs, Container, Link, Typography} from "@mui/material";

const BrandDetail = () => {
    const {clientId, brandId} = useParams();
    const [identityProviders, setIdentityProviders] = useState<IdentityProviderResponse[]>([]);
    const [brandDetails, setBrandDetails] = useState<BrandResponse | undefined>(undefined);
    const [journeys, setJourneys] = useState<JourneyResponse[] | undefined>(undefined);
    const [users, setUsers] = useState<UserResponse[] | undefined>(undefined);

    const navigate = useNavigate();
    const {logError} = useContext(NotificationContext);

    const createJourneyButtonHandler = () => {
        navigate(`/clients/${clientId}/brands/${brandId}/journeys/new`);
    }

    const editJourneyButtonHandler = (journeyId : string, editTemplateParams : boolean) => {
        if (editTemplateParams) {
            navigate(`/clients/${clientId}/brands/${brandId}/journeys/${journeyId}`);
        } else {
            navigate(`/clients/${clientId}/brands/${brandId}/journeys/${journeyId}/props`);
        }        
    }

    const createUserCallback = useCallback((req: CreateUserRequest, onSuccess: Function, onError: Function)=>{
        ClientService.createUsers(req, clientId, brandId, onSuccess, onError);
    },[clientId, brandId])

    const reloadUsersCallback = useCallback(()=> {
        ClientService.getUsers(clientId, brandId).then(response => {
            setUsers(response.data);
        });
    }, [clientId, brandId, setUsers])

    useEffect(() => {
        (async () => {
            const {data} = await ClientService.getIdentityProvidersByBrand(clientId, brandId);
            setIdentityProviders(data);
        })();

        ClientService.getBrandById(clientId, brandId).then(response => {
            setBrandDetails(response.data);
        }).catch(e => logError("failed to get brand details"));

        ClientService.getJourneys(clientId, brandId).then(response => {
            setJourneys(response.data);
        }).catch(e => logError("failed to get brand's journeys"));

        ClientService.getUsers(clientId, brandId).then(response => {
           setUsers(response.data);
        }).catch(e => logError("failed to get brand's users"));
    }, [clientId, brandId, setIdentityProviders, logError])

    return <header>
        <HeaderBar/>
        <Container maxWidth={"xl"} sx={{marginBottom: 5}}>
            <Breadcrumbs aria-label="breadcrumb" id="breadcrumb">
                <Link color="inherit" href="/">
                    EMS Management
                </Link>
                <Link color="inherit" href="/clients">
                    Clients
                </Link>
                <Link color="inherit" href={"/clients/" + clientId}>
                    {clientId}
                </Link>
                <Typography color="inherit">
                    Brands
                </Typography>
                <Typography color="textPrimary">{brandId}</Typography>
            </Breadcrumbs>

            <div style={{display: 'flex', justifyContent: 'space-between',}}>
                <h1>Brand Details</h1>
            </div>

            <BrandDetailsForm
                brandDetails={brandDetails}
                users={users}
                createUserCallback={createUserCallback}
                reloadUsersCallback={reloadUsersCallback}
                editBrandPath={`/clients/${clientId}/brands/${brandId}/edit`}
            />

            {
                identityProviders.filter(x => x.type === IdentityProviderType.SAML).map(
                    samlProvider => {
                        return (
                            <SAMLProviderForm key={samlProvider.providerName}
                                              samlProvider={samlProvider as SamlResponse}/>
                        )
                    }
                )
            }
            {
                identityProviders.filter(x => x.type === IdentityProviderType.OIDC).map(
                    oidcProvider => {
                        return (
                            <OIDCProviderForm key={oidcProvider.providerName}
                                              oidcProvider={oidcProvider as OpenIdConnectResponse}/>
                        )
                    }
                )
            }

            {
                journeys && <JourneyForm
                    journeys={journeys}
                    onCreateJourney={createJourneyButtonHandler}
                    onUpdateJourney={editJourneyButtonHandler}
                />
            }
        </Container>
    </header>
}

export default BrandDetail;
