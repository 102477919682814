import React from 'react';
import { BrandResponse } from "../../models/BrandResponse";
import { format } from 'date-fns'
import { Grid, TextField, Theme, Switch, FormControlLabel } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { UserResponse } from "../../models/UserResponse";
import UsersListView from "./UsersListView";
import CreateUserView from "./CreateUserView";
import { CreateUserRequest } from "../../models/CreateUserRequest";
import CreateLocations from '../common/CreateLocations';
import {i9FormFields} from "../common/UiFieldList";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                paddingTop: 20,
                width: '95%',
            },
        },
        inputBoxTop: {
            paddingTop: '5px',
        },
        inputBoxBetween: {
            paddingLeft: '5px',
        },
        halfWidth: {
            width: '50%'
        },
        transferList: {
            paddingTop: '5px'
        }
    }),
);
const BrandDetailsForm = ({ brandDetails, users, createUserCallback, reloadUsersCallback, editBrandPath }:
    {
        brandDetails: BrandResponse | undefined,
        users: UserResponse[] | undefined,
        createUserCallback: (req: CreateUserRequest, onSuccess: Function, onError: Function) => void,
        reloadUsersCallback: () => void,
        editBrandPath: string,
    }) => {
    const classes = useStyles();

    const formatDate = (date: string | undefined, dateFormat: string) => {
        if (date) {
            const dateObj = new Date(date);
            return format(dateObj, dateFormat);
        }
    }
    return <form id='clientDetail' className={classes.root} autoComplete="off">
        <Grid container spacing={1}>
            <Grid container item xs={6} spacing={1} alignContent={"flex-start"}>
                <Grid item xs={12} sx={{paddingBottom: "2px"}}>
                    <Button
                        variant={"contained"}
                        href={editBrandPath}
                    >
                        Update Brand Details
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="clientName"
                        label="Name"
                        value={brandDetails?.name}

                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth={true}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className={classes.inputBoxTop}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="clientContactName"
                        label="Contact Name"
                        value={brandDetails?.contactName}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth={true}
                        variant="outlined"
                        className={classes.inputBoxTop}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="allowedEmailDomains"
                        label="Allowed Email Domains"
                        fullWidth={true}
                        value={brandDetails?.allowedEmailDomains}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }} />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="clientPhoneNumber"
                        label="Contact Phone Number"
                        fullWidth={true}
                        value={brandDetails?.contactPhoneNumber}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="clientContactEmail"
                        label="Contact Email"
                        fullWidth={true}
                        value={brandDetails?.contactEmail}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="clientStartDate"
                        label="Start Date"
                        fullWidth={true}
                        value={formatDate(brandDetails?.startDate, 'yyyy-MM-dd')}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="clientEndDate"
                        label="End Date"
                        fullWidth={true}
                        value={formatDate(brandDetails?.endDate, 'yyyy-MM-dd')}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                {brandDetails?.hiringManagers && brandDetails?.hiringManagers?.length !== 0 ? <>
                    <Grid item xs={6}>
                        <TextField
                            id="hiringManagerLastName"
                            label="Hiring Manager Last Name"
                            fullWidth={true}
                            value={brandDetails?.hiringManagers[0]?.lastName}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="hiringManagerName"
                            label="Hiring Manager First Name"
                            fullWidth={true}
                            value={brandDetails?.hiringManagers[0]?.name}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="hiringManagerEmail"
                            label="Hiring Manager Email"
                            fullWidth={true}
                            value={brandDetails?.hiringManagers[0]?.email}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="hiringManagerPhone"
                            label="Hiring Manager Phone"
                            fullWidth={true}
                            value={brandDetails?.hiringManagers[0]?.phone}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="hiringManagerTitle"
                            label="Hiring Manager Title"
                            fullWidth={true}
                            value={brandDetails?.hiringManagers[0]?.title}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </> : <></>
                }
                {brandDetails?.i9Config?.everifyEnabled && <>
                    <Grid item xs={6}>
                        <TextField
                            id="everifyAccountId"
                            label="E-Verify Account ID"
                            fullWidth={true}
                            value={brandDetails?.i9Config.everify_account_id}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="everifyCaseCreatorName"
                            label="E-Verify Case Creator Name"
                            fullWidth={true}
                            value={brandDetails?.i9Config.everify_case_creator_name}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="everifyCaseCreatorEmail"
                            label="E-Verify Case Creator Email"
                            fullWidth={true}
                            value={brandDetails?.i9Config.everify_case_creator_email}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="everifyCaseCreatorPhone"
                            label="E-Verify Case Creator Phone"
                            fullWidth={true}
                            value={brandDetails?.i9Config.everify_case_creator_phone}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="everifyCaseCreatorUsername"
                            label="E-Verify Case Creator Username"
                            fullWidth={true}
                            value={brandDetails?.i9Config.everify_case_creator_username}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="everifyCaseCreatorPassword"
                            label="E-Verify Case Creator Password"
                            fullWidth={true}
                            value={brandDetails?.i9Config.everify_case_creator_password}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type={"password"}
                            />
                    </Grid>
                </>
                }
            </Grid>

            <Grid container item xs={6} spacing={1}>
                {brandDetails?.i9Config ? <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={brandDetails.i9Config.everifyEnabled}
                                    disabled
                                    color="primary"
                                />
                            }
                            label="E-Verify Enabled"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={brandDetails.i9Config.is_employer}
                                    disabled
                                    color="primary"
                                />
                            }
                            label="Is Employer Flow"
                        />
                        {
                            i9FormFields.map(field => <TextField
                                key={`${field.name}-field`}
                                value={brandDetails?.i9Config?.[field.name]}
                                label={field.label}
                                type="text" fullWidth={true}
                                InputProps={{readOnly: true,}}
                                sx={{marginTop: 1}}
                            />)
                        }
                        <TextField
                            key={`rep-field`}
                            value={JSON.stringify(brandDetails?.i9Config?.authorized_representative, null, 2)}
                            label={"Authorized Representative (JSON)"}
                            type="text" fullWidth={true}
                            multiline rows={3}
                            InputProps={{readOnly: true,}}
                            sx={{marginTop: 2}}
                        />

                    </Grid>
                    : <></>}
            </Grid>

            {
                brandDetails?.i9Config && <Grid item container xs={12}>
                    <CreateLocations locations={brandDetails.i9Config.locations} onChange={() => undefined} readOnly={true}/>
                </Grid>
            }

            <Grid item container xs={9}>
                {users && <UsersListView users={users} reloadUsersCallback={reloadUsersCallback} />}
            </Grid>
            <Grid item container xs={3}>
                <CreateUserView
                    createUserCallback={createUserCallback}
                    reloadUsersCallback={reloadUsersCallback}
                    brandAllowedEmailDomains={brandDetails?.allowedEmailDomains?.split(",") || []}
                />
            </Grid>
        </Grid>
    </form>
}

export default BrandDetailsForm;
