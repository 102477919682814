import React from 'react';
import {IconButton, InputAdornment, TextField, Theme, Button} from "@mui/material";
import {ReminderConfigurationResponse} from "../../models/ReminderConfigurationResponse";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonPadding: {
            paddingTop: '5px',
        },

    }),
);
const ReminderConfigList = ({addReminder, reminderConfigs, handleReminders, removeReminder}: {
    addReminder: () => void,
    reminderConfigs: { number: ReminderConfigurationResponse },
    handleReminders: (idx: number, e: any) => void,
    removeReminder: (idx: number) => void
}) => {
    const classes = useStyles();

    let reminders: ReminderConfigurationResponse[] = [];

    if( reminderConfigs ){
        reminders = Object.values(reminderConfigs);
    }

    return (
        <div> {

            reminders.map(
                    (reminderConfig, idx) => {
                        return (
                            <>
                            <TextField id={idx + "ReminderTextField"}
                                       key={idx}
                                       label="Days to Remind"
                                       type="text" fullWidth={true}
                                       value={reminderConfig.daysToRemind}
                                       onChange={(e) => handleReminders(idx, e)}
                                       InputProps = {{
                                           endAdornment: <InputAdornment position="end">
                                               <IconButton edge="end" color={"primary"} id={idx+"removeReminderConfig"}>
                                                    <DeleteOutlineIcon onClick={(e) => removeReminder(idx)}
                                                              aria-label="delete reminder config"/>
                                               </IconButton>
                                           </InputAdornment>
                                       }}
                            />
                            </>
                        )
                    })

        }
            <div className={classes.buttonPadding}>
                <Button
                    variant="outlined"
                    size="small"
                    id="addReminderConfig"
                    color={"primary"}
                    onClick={(e) => addReminder()}
                    aria-label="Add Reminder Config" disabled={reminders.length === 3}>
                    Add Reminder
                </Button>
            </div>
        </div>
    );
}

export default ReminderConfigList;
