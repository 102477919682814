import { Button, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Location } from "../../models/Location";

const LocationForm = (params: {
    location: Location;
    onChange: (location: Location) => void;
    readOnly: boolean,
    onDelete: () => void;
}) => {
    const [location, setLocation] = useState<Location>(params.location);
    const [focusedField, setFocusedField] = useState<string>("");

    useEffect(() => {
        setLocation(params.location);
    }, [params.location]);

    useEffect(() => {
        if (location) {
            params.onChange(location);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const onLocationFieldChange = (key: string, value: string) => {
        const updated: any = { ...location };
        updated[key] = value;
        setLocation(updated);
    };

    const handleInputValue = (event: any, field: string) => {
        onLocationFieldChange(field, event.target.value)
    }

    function Field(p: { field: string; label: string }) {
        const value = (location as any)[p.field];
        const isFocused = focusedField === p.field;
        return (
            <FormControlLabel
                control={
                    <TextField
                        id={p.field}
                        key={p.field}
                        label={p.label}
                        InputProps={{
                            readOnly: params.readOnly
                        }}
                        value={value}
                        required={p.field !== 'state'}
                        type="text"
                        style={{ marginRight: "0.5rem" }}
                        onChange={(e) => handleInputValue(e, p.field)}
                        onFocus={() => setFocusedField(p.field)}
                        onBlur={() => setFocusedField("")}
                        variant="standard"
                        autoFocus={isFocused}
                    />
                }
                label=""
            />
        );
    }

    return (
        <div
            style={{
                backgroundColor: "#F3F3F3",
                borderRadius: "0.75rem",
                padding: "1rem",
                display: "flex",
                marginBottom: "0.75rem",
            }}
        >
            <Field field="name" label="Name" />
            <Field field="street" label="Street name or number" />
            <Field field="cityTown" label="City / Town" />
            <Field field="state" label="State / County" />
            <Field field="zip" label="Zip code / Postcode" />
            <div>
                <InputLabel id="timezone-selector-label">Timezone *</InputLabel>
                        <Select
                            labelId="timezone-selector-label"
                            id="timezone-selector"
                            autoWidth={true}
                            label="Timezone"
                            value={location.timezone}
                            name="timezone"
                            
                            readOnly={params.readOnly}
                            onChange={e => handleInputValue(e, 'timezone')}
                            onFocus={() => setFocusedField('timezone')}
                            onBlur={() => setFocusedField("")}
                        >
                            <MenuItem value='PT'>Pacific Time (PT)</MenuItem>
                            <MenuItem value='MT'>Mountain Time (MT)</MenuItem>
                            <MenuItem value='CT'>Central Time (CT)</MenuItem>
                            <MenuItem value='ET'>Eastern Time (ET)</MenuItem>
                            <MenuItem value='HT'>Hawaii Time (HT)</MenuItem>
                            <MenuItem value='AKT'>Alaska Time (AKT)</MenuItem>
                            <MenuItem value='UK'>Europe/London</MenuItem>
                            <MenuItem value='SAST'>Africa/Johannesburg</MenuItem>
                        </Select>
            </div>
            {!params.readOnly && location.id === undefined && <Button onClick={params.onDelete}>Remove</Button>}
        </div>
    );
};

type LocationEvent = {
    target: {
        name: string;
        value: Location[]
    }
}

const CreateLocations = (params: { locations?: Location[], onChange?: (event: LocationEvent) => void, readOnly?: boolean }) => {
    const [locations, setLocations] = useState<Location[]>(params.locations || []);

    useEffect(() => {
        if (locations && params.onChange) {
            params.onChange({
                target: {
                    name: "locations",
                    value: locations,
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations]);

    const onLocationChange = (location: Location, index: number) => {
        const updated = [...locations];
        updated[index] = location;
        setLocations(updated);
    };

    const addNewLocation = () => {
        setLocations([
            ...locations,
            { name: "", street: "", cityTown: "", state: "", zip: "", timezone: "" },
        ])
    }

    return (
        <div>
            <div style={{ display: "flex" }}>
                <h4>Locations:</h4>
                {!params.readOnly && <Button onClick={() => addNewLocation()}>
                    + Add
                </Button>
                }
            </div>
            {locations.map((location, index) => (
                <LocationForm
                    key={index}
                    location={location}
                    onChange={(location) => onLocationChange(location, index)}
                    readOnly={params.readOnly || false}
                    onDelete={() =>
                        setLocations(locations.filter((l) => l !== location))
                    }
                />
            ))}
        </div>
    );
};

export default CreateLocations;
