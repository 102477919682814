import {JourneyResponse} from "../../models/JourneyResponse";
import React, {useEffect, useState} from "react";
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import JourneyView from "../JourneyView";

const JourneyForm = ({journeys, onCreateJourney, onUpdateJourney}: { journeys: JourneyResponse[], onCreateJourney: ()=>void, onUpdateJourney: (journeyId: string, editTemplateParams: boolean) => void }) => {
    const [createdAt, setCreatedAt] = useState<string>('');
    const [journey, setJourney] = useState<JourneyResponse|undefined>(undefined);

    useEffect(() => {
        if (journeys?.length > 0) {
            const filteredJourneys = journeys.filter(i => i.createdAt === createdAt);
            const journey = filteredJourneys && filteredJourneys.length > 0 ? filteredJourneys[0] : undefined;
            setJourney(journey);
        }
    }, [createdAt, journeys]);

    useEffect(() => {
        if (journeys && journeys.length > 0) {
            const sortJourneys = () => journeys.sort((a,b)=>
                new Date(b.createdAt).getTime()-new Date(a.createdAt).getTime());
            setCreatedAt(sortJourneys()[0].createdAt);
        }
    }, [journeys]);

    const handleSelectChange = (event: SelectChangeEvent) => {
        setCreatedAt( event.target.value);
    };

    return (
        <Grid container sx={{marginTop: 2}} spacing={1}>
            <Grid item container xs={12}>
                <Typography variant="h5" color={"textPrimary"} align={"left"}>Journeys</Typography>
                <Button onClick={onCreateJourney}
                        variant={"contained"} startIcon={<AddIcon/>} sx={{fontSize: 12, marginLeft: 20}}>Create New Journey</Button>
            </Grid>
            {journey && <Grid sx={{ minWidth: 270, marginTop:2, marginBottom:1 }} item>
                <FormControl fullWidth>
                    <InputLabel id="selectJourneyLabelId">Created At</InputLabel>
                    <Select id="selectJourneyId"
                            value={createdAt}
                            label="CreatedAt"
                            labelId="selectJourneyLabelId"
                            onChange={handleSelectChange}>
                        {journeys.map((journey) => (
                            <MenuItem
                                key={journey.createdAt}
                                value={journey.createdAt}>
                                {journey.createdAt}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            }
            {
                journey && <JourneyRenderer journey={journey} onUpdateJourney={onUpdateJourney}/>
            }
        </Grid>
    );
}

const JourneyRenderer = ({journey, onUpdateJourney}: { journey: JourneyResponse, onUpdateJourney: (journeyId: string, editTemplateParams: boolean) => void }) => {
    return (
        <Grid item container key={journey.id}>
            <Grid item xs={2.4}><Typography variant="caption" color={"textSecondary"} align={"left"}>ID: {journey.id}</Typography></Grid>
            <Grid item xs={2.4}><Typography variant="caption" color={"textSecondary"} align={"left"}>Name: {journey.journeyName}</Typography></Grid>
            <Grid item xs={2.4}><Typography variant="caption" color={"textSecondary"} align={"left"}>Created At: {journey.createdAt}</Typography></Grid>
            <Grid item xs={2.4} sx={{paddingLeft: 2, paddingRight: 2}}>
                <Button
                    onClick={()=>{ onUpdateJourney(journey.id, false) }}
                    startIcon={<AppRegistrationIcon/>}
                    variant="contained"
                    fullWidth
                >
                    Edit Properties
                </Button>
            </Grid>
            <Grid item xs={2.4} sx={{paddingLeft: 2, paddingRight: 2}}>
                {
                    journey.params && Object.keys(journey.params).length > 0 && <Button
                        onClick={()=>{ onUpdateJourney(journey.id, true) }}
                        startIcon={<AppRegistrationIcon/>}
                        variant="contained"
                        fullWidth
                    >
                        Edit Template Parameters
                    </Button>
                }
            </Grid>
            <Grid item container alignContent={"flex-start"} xs={6}>
                {
                    journey.params && Object.keys(journey.params).length > 0 ?
                        Object.entries(journey.params).map(([key, value])=><Grid key={`${journey.id}-${key}`} item xs={12} sx={{marginTop: 2}}>
                            <TextField name={key} label={key}
                                       required={true} type={"text"} fullWidth={true}
                                       value={value}
                                       InputProps={{
                                           readOnly: true,
                                       }}
                            />
                        </Grid>)
                        :
                        <TextField
                            fullWidth={true}
                            value={JSON.stringify(journey.journeyDetails, null, 2)}
                            multiline={true}
                            rows={24}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                }
            </Grid>
            <Grid item xs={6} sx={{height: 600}}>
                <JourneyView journeySteps={journey.journeyDetails.steps}/>
            </Grid>
        </Grid>
    )
}

export default JourneyForm;
