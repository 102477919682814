import LocalStorageService from "../LocalStorageService/LocalStorageService";
import jwt_decode, {JwtPayload} from 'jwt-decode';

export default class AuthenticationService {

    /**
     * You're authenticated unless you don't have a token, or your token has expired.
     * If your token expires - it removes the JWT from local storage and removes the shortcut
     * for determining logged in status.
     */
    static isAuthenticated(): boolean {
        const token = LocalStorageService.getToken();
        let isAuthenticated = true;

        if (token) {
            const jwt: JwtPayload = jwt_decode(token);
            const expiry = jwt.exp;
            const now = Date.now() / 1000;
            if (expiry && now > expiry) {
                console.log("expired");
                LocalStorageService.removeToken();
                isAuthenticated = false;
            }
        } else {
            isAuthenticated = false;
        }
        return isAuthenticated;
    }

    static isAdmin(): boolean {
        const token = LocalStorageService.getToken();
        if (token) {
            const jwt: any = jwt_decode(token);
            return jwt["cognito:groups"]?.includes("admin");
        }
        return false;
    }
}
