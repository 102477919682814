import axios, {AxiosResponse} from "axios";
import {ConfigService} from "./ConfigService/ConfigService";
import {CreateClientRequest} from "../models/CreateClientRequest";
import {ClientResponse} from "../models/ClientResponse";
import LocalStorageService from "./LocalStorageService/LocalStorageService";
import {DocumentTypeResponse} from "../models/DocumentTypeResponse";
import {ReminderConfigurationResponse} from "../models/ReminderConfigurationResponse";
import {IdentityProviderResponse} from "../models/IdentityProviderResponse";
import {BrandResponse} from "../models/BrandResponse";
import {CreateBrandRequest} from "../models/CreateBrandRequest";
import {CreateJourneyRequest} from "../models/CreateJourneyRequest";
import {JourneyResponse} from "../models/JourneyResponse";
import {CreateUserRequest, UserGroupType} from "../models/CreateUserRequest";
import {UserResponse} from "../models/UserResponse";
import {JourneyTemplate} from "../models/JourneyTemplate";
import {CreateTemplatedJourneyRequest} from "../models/CreateTemplatedJourneyRequest";
import {GeneratePdfRequest} from "../models/GeneratePdfRequest";
import {GeneratedPdfResponse} from "../models/GeneratedPdfResponse";
import { UpdateJourneyPropertiesRequest } from "../models/UpdateJourneyPropertiesRequest";
import { UserPermissionsView } from "../models/UserPermissionsView";
import { SaveUserPermissionRequest } from "../models/SaveUserPermissionRequest";

class ClientService {
    clientsEndpoint: string = ConfigService.getConfigValue('REACT_APP_CLIENTS_ENDPOINT');
    journeyTypeEndpoint: string = ConfigService.getConfigValue('REACT_APP_JOURNEY_TYPES_ENDPOINT');
    userGroupsEndpoint: string = ConfigService.getConfigValue('REACT_APP_USER_GROUPS_ENDPOINT')

    createClient = (req: CreateClientRequest, success: Function, error: Function) => {
        console.log("Creating client")
        req.allowedEmailDomains = stripSpecialCharsFromEmail(req.allowedEmailDomains);
        axios.post(this.clientsEndpoint, req, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            }
        })
            .then(res => {
                success(res)
            })
            .catch(err => {
                error(err);
            })
    }

    getClients = () => {
        console.log("Getting clients")
        return axios.get<ClientResponse[]>(this.clientsEndpoint, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            },
            params: {
                page: 0,
                size: 9999
            }
        });
    }

    getClient = (clientId: string) => {
        console.log("Getting client " + clientId);
        const url = this.clientsEndpoint + '/' + clientId;
        return axios.get<ClientResponse>(url, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            },
        });
    }

    createBrand = (req: CreateBrandRequest, clientId: string, success: Function, error: Function) => {
        console.log("Creating brand")
        req.allowedEmailDomains = stripSpecialCharsFromEmail(req.allowedEmailDomains);
        if (req.i9Config) {
            req.i9Config.everifyEnabled = req.i9Config.everifyEnabled || false;
        }
        axios.post(this.clientsEndpoint + '/' + clientId + '/brands', req, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            }
        })
            .then(res => {
                success(res)
            })
            .catch(err => {
                error(err);
            })
    }

    updateBrand = (req: CreateBrandRequest, clientId: string, brandId: string, success: Function, error: Function) => {
        console.log("Updating brand")
        req.allowedEmailDomains = stripSpecialCharsFromEmail(req.allowedEmailDomains);
        if (req.i9Config) {
            req.i9Config.everifyEnabled = req.i9Config.everifyEnabled || false;
        }
        axios.patch(this.clientsEndpoint + '/' + clientId + '/brands/' + brandId, req, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            }
        })
            .then(res => {
                success(res)
            })
            .catch(err => {
                error(err);
            })
    }

    getBrands = (clientId: string) => {
        console.log("Getting brands " + clientId);
        const url = this.clientsEndpoint + '/' + clientId + '/brands';
        return axios.get<BrandResponse[]>(url, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            },
        });
    }

    getUserPermissions = (domain: string) => {
        console.log("Getting user permissions for " + domain);
        const url = this.clientsEndpoint + '/permissions/' + domain;
        return axios.get<UserPermissionsView>(url, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            },
        });
    }

    saveUserPermissions = (req: SaveUserPermissionRequest, success: Function, error: Function) => {
        console.log("Updating user permissions")
        axios.put(this.clientsEndpoint + '/uploadUserPermission', req, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            }
        })
            .then(res => {
                success(res)
            })
            .catch(err => {
                error(err);
            })
    }

    getBrandById = (clientId: string, brandId: string) => {
        console.log("Getting brand by id " + clientId);
        const url = this.clientsEndpoint + '/' + clientId + '/brands/' + brandId;
        return axios.get<BrandResponse>(url, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            },
        });
    }

    createJourney = (req: CreateJourneyRequest, clientId: string, brandId: string, success: Function, error: Function) => {
        console.log("Creating journey")
        axios.post(this.clientsEndpoint + '/' + clientId + '/brands/' + brandId + '/journeys', req, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            }
        })
            .then(res => {
                success(res)
            })
            .catch(err => {
                error(err);
            })
    }

    createTemplatedJourney = (req: CreateTemplatedJourneyRequest, clientId: string, brandId: string, success: Function, error: Function) => {
        console.log("Creating templated journey")
        axios.post(this.clientsEndpoint + '/' + clientId + '/brands/' + brandId + '/journeys/templated', req, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            }
        })
            .then(res => {
                success(res)
            })
            .catch(err => {
                error(err);
            })
    }

    updateJourneyParameters = (req: {[key: string]: string}, journeyId: string, success: Function, error: Function) => {
        console.log("Updating templated journey")
        axios.put(this.clientsEndpoint.slice(0,-8) + '/journeys/' + journeyId + '/params', req, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            }
        })
            .then(res => {
                success(res)
            })
            .catch(err => {
                error(err);
            })
    }

    updateJourneyProperties = (req: UpdateJourneyPropertiesRequest, journeyId: string, success: Function, error: Function) => {
        console.log("Updating journey properties")
        axios.put(this.clientsEndpoint.slice(0,-8) + '/journeys/' + journeyId + '/props', req, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            }
        })
            .then(res => {
                success(res)
            })
            .catch(err => {
                error(err);
            })
    }

    getJourneys = (clientId: string, brandId: string) => {
        console.log("Getting journey for client: " + clientId + " brand: " + brandId);
        const url = this.clientsEndpoint + '/' + clientId + '/brands/' + brandId + '/journeys';
        return axios.get<JourneyResponse[]>(url, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            },
        });
    }

    getJourneyTemplates = () => {
        console.log("Getting journey templates")
        return axios.get<JourneyTemplate[]>(this.clientsEndpoint.slice(0,-8) + '/journeys/templates', {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            }
        });
    }

    createUsers = (req: CreateUserRequest, clientId: string, brandId: string, onSuccess: Function, onError: Function) => {
        console.log("Creating user")
        axios.post(this.clientsEndpoint + '/' + clientId + '/brands/' + brandId + '/users', req, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            }
        })
            .then(res => {
                onSuccess(res)
            })
            .catch(err => {
                onError(err);
            })
    }

    getUsers = (clientId: string, brandId: string) => {
        console.log("Getting users for client: " + clientId + " brand: " + brandId);
        const url = this.clientsEndpoint + '/' + clientId + '/brands/' + brandId + '/users';
        return axios.get<UserResponse[]>(url, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            },
        });
    }

    deleteUser = (clientId: string, brandId: string, userId: string) => {
        console.log("Delete users for brand: " + clientId + " brand: " + brandId);
        const url = this.clientsEndpoint + '/' + clientId + '/brands/' + brandId + '/users/' + userId;
        return axios.delete(url, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            },
        });
    }

    storeDocumentTypesForClient = (clientId: string, listToStore: DocumentTypeResponse[]) => {
        const url = this.clientsEndpoint + '/' + clientId + '/documentTypes';
        return axios.put(url, listToStore.map(dtr => dtr.id), {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            },
        });
    }


    getAssignedDocumentTypes = (clientId: string) => {
        const url = this.clientsEndpoint + '/' + clientId + '/documentTypes';
        return axios.get<DocumentTypeResponse[]>(url, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            },
        });
    }

    storeReminderConfigurationsForClient = (clientId: string, listToStore: ReminderConfigurationResponse[]) => {
        const url = this.clientsEndpoint + '/' + clientId + '/reminderConfigurations';
        return axios.put(url, listToStore, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            },
        });
    }

    getAssignedReminderConfigurations = (clientId: string) => {
        const url = this.clientsEndpoint + '/' + clientId + '/reminderConfigurations';
        return axios.get<ReminderConfigurationResponse[]>(url, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            },
        });
    }

    getIdentityProvidersByBrand = (clientId: string, brandId: String): Promise<AxiosResponse<IdentityProviderResponse[]>> => {
        const url = this.clientsEndpoint + '/' + clientId + '/brands/' + brandId + '/identityProviders'
        return axios.get<IdentityProviderResponse[]>(url, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            },
        });
    }

    getJourneyTypes = (): Promise<string[]> => {
        const url = this.journeyTypeEndpoint;
        return axios.get<string[]>(url, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            },
        }).then(response => response.data);
    }

    generatePdf = (req: GeneratePdfRequest, onSuccess: (res: GeneratedPdfResponse) => void, onError: (err?: any) => void) => {
        console.log("Generating pdf files for Terms & Conditions and Privacy Policy");
        axios.post<GeneratedPdfResponse>(this.clientsEndpoint.slice(0, -7) + "generatePdf", req, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            }
        })
            .then(res => {
                onSuccess(res.data)
            })
            .catch(err => {
                onError(err);
            });
    }

    uploadMedia = (mediaFile: File, clientName: string, onSuccess: (url: string) => void, onError: (err?: any) => void) => {
        console.log("Uploading media file for public S3 access");

        const req = new FormData();
        req.append('file', mediaFile);
        req.append('clientName', clientName);

        axios.post<string>(this.clientsEndpoint.slice(0, -7) + "uploadMedia", req, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            }
        })
            .then(res => {
                onSuccess(res.data)
            })
            .catch(err => {
                onError(err);
            });
    }

    updateServiceStatus = (payload: any, onSuccess: (url: string) => void, onError: (err?: any) => void) => {
        console.log("Updating Service Status");

        axios.post(this.clientsEndpoint.slice(0, -7) + "updateStatus", payload, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            }
        })
        .then(res => {
            onSuccess(res.data)
        })
        .catch(err => {
            onError(err);
        })
    }

    getUserGroups = (): Promise<UserGroupType[]> => {
        const url = this.userGroupsEndpoint;
        return axios.get<UserGroupType[]>(url, {
            headers: {
                'Authorization': 'Bearer ' + LocalStorageService.getToken(),
            },
        }).then(response => response.data);
    }
}

const clientServiceSingleton = new ClientService();
export default clientServiceSingleton;

const stripSpecialCharsFromEmail = (email: string) => {
    return email?.toLowerCase().replaceAll(" ", "").replace("@", "");
}
