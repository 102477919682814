import React, {useContext, useEffect, useState} from "react";
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField
} from "@mui/material";
import {CreateUserRequest, UserGroupType} from "../../../models/CreateUserRequest";
import clientService from "../../../services/ClientService";
import NotificationContext from "../../../context/NotificationContext";

const CreateUserView = ({createUserCallback, reloadUsersCallback, brandAllowedEmailDomains}: {
    createUserCallback: (req: CreateUserRequest, onSuccess: Function, onError: Function) => void,
    reloadUsersCallback: ()=>void,
    brandAllowedEmailDomains: string[]
}) => {
    const {logError} = useContext(NotificationContext);
    const [uploading, setUploading] = useState(false);
    const [groups, setGroups] = useState<UserGroupType[]>([]);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [givenName, setGivenName] = useState("");
    const [familyName, setFamilyName] = useState("");
    const [selectedGroups, setSelectedGroups] = useState<string[]>(["GENERAL"]);

    useEffect(() => {
        clientService.getUserGroups()
            .then(userGroups =>  {
                setGroups(userGroups);
            })
            .catch(e => logError("Failed to load user groups"));
    }, [logError]);

    const handleGroupChange = (event: SelectChangeEvent<typeof selectedGroups>) => {
        const {
            target: { value },
        } = event;
        setSelectedGroups(
            // On autofill we get a stringified value.
            typeof value === 'string' ? (value as string).split(',') : value
        );
    };

    const createUserHandler = () => {
        if(!email.includes("@")) {
            alert("Email is invalid");
            return;
        }
        const emailStripped = email?.toLowerCase().replaceAll(" ", "");
        const domain = emailStripped.split("@")[1];

        if(!brandAllowedEmailDomains.includes(domain)) {
            alert(`Email domain: ${domain} is not in list of allowed domains: ${brandAllowedEmailDomains}`);
            return;
        }

        if(selectedGroups.length <= 0) {
            alert("You must select at least one group");
            return;
        }

        setUploading(true);

        createUserCallback(
            {
                email: emailStripped,
                temporaryPassword: password,
                givenName: givenName,
                familyName: familyName,
                groups: selectedGroups
            },
            ()=>{
                setEmail("");
                setPassword("");
                setFamilyName("");
                setGivenName("");
                setSelectedGroups(["GENERAL"]);
                reloadUsersCallback();
                setUploading(false);
            },
            (e: string)=>{
                alert(e)
                setUploading(false);
            }
        )
    }

    return (
                <Grid container spacing={1} style={{background:"#eee", padding: 5}}>
                    <Grid item xs={12}>
                        <TextField
                            label={"Email"}
                            type={"email"}
                            fullWidth={true}
                            required={true}
                            value={email}
                            onChange={(e)=>{setEmail(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={"Temporary Password"}
                            type={"password"}
                            fullWidth={true}
                            required={true}
                            value={password}
                            onChange={(e)=>{setPassword(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={"Given Name"}
                            fullWidth={true}
                            required={true}
                            value={givenName}
                            onChange={(e)=>{setGivenName(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={"Family Name"}
                            fullWidth={true}
                            required={true}
                            value={familyName}
                            onChange={(e)=>{setFamilyName(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl sx={{width: "100%"}}>
                            <InputLabel id="labelGroupId">Group</InputLabel>
                            <Select
                                labelId="labelGroupId"
                                label={"Group"}
                                multiple={true}
                                fullWidth={true}
                                required={true}
                                value={selectedGroups}
                                onChange={handleGroupChange}>
                                    {groups.map(group =>
                                        (<MenuItem
                                            key={group.toString()}
                                            value={group.toString()}>
                                            {group.toString()}
                                        </MenuItem>))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            onClick={createUserHandler}
                            variant={"contained"}
                            style={{width: "100%"}}
                            disabled={email.length === 0 || password.length < 8 || givenName.length === 0 || familyName.length === 0 || uploading || groups.length === 0 }
                        >Create User</Button>
                    </Grid>
                </Grid>
    )
}

export default CreateUserView;
