import * as React from 'react';
import clsx from 'clsx';
import { withStyles, WithStyles } from '@mui/styles';
import { Theme, createTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import {
    AutoSizer,
    Column, IndexRange, OverscanIndexRange, ScrollEventData, ScrollParams,
    Table, TableCellDataGetter,
    TableCellRenderer,
    TableHeaderProps,
} from 'react-virtualized';

const styles = (theme: Theme) =>
    ({
            flexContainer: {
                    display: 'flex',
                    alignItems: 'center',
                    boxSizing: 'border-box',
            },
            table: {
                    // ↓↓ below comments copied from: https://mui.com/components/tables/ ↓↓
                    // temporary right-to-left patch, waiting for
                    // https://github.com/bvaughn/react-virtualized/issues/454
                    '& .ReactVirtualized__Table__headerRow': {
                            ...(theme.direction === 'rtl' && {
                                    paddingLeft: '0 !important',
                            }),
                            ...(theme.direction !== 'rtl' && {
                                    paddingRight: undefined,
                            }),
                    },
            },
            tableRow: {
                    cursor: 'pointer',
            },
            tableRowHover: {
                    '&:hover': {
                            backgroundColor: theme.palette.grey[200],
                    },
            },
            tableCell: {
                    flex: 1,
            },
            noClick: {
                    cursor: 'initial',
            },
    } as const);

interface ColumnData {
        dataKey: string;
        label: string;
        numeric?: boolean;
        width: number;
        dataGetter?: TableCellDataGetter;
        urlGetter?: ({ rowData } : {rowData: any}) => { label : string, url : string };
}

interface Row {
        index: number;
}

interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
        columns: readonly ColumnData[];
        headerHeight?: number;
        onRowClick?: () => void;
        rowCount: number;
        rowGetter: (row: Row) => any;
        rowHeight?: number;
        scrollToIndex?: number;
        onRowsRendered?: (info: IndexRange & OverscanIndexRange) => void | undefined;
        onScroll?: (((params: ScrollParams) => any) & ((info: ScrollEventData) => void)) | undefined
}

class MuiVirtualizedTable extends React.PureComponent<MuiVirtualizedTableProps> {
        static defaultProps = {
                headerHeight: 48,
                rowHeight: 48,
        };

        getRowClassName = ({ index }: Row) => {
                const { classes, onRowClick } = this.props;

                return clsx(classes.tableRow, classes.flexContainer, {
                        [classes.tableRowHover]: index !== -1 && onRowClick != null,
                });
        };

        cellRenderer: TableCellRenderer = ({ cellData, columnIndex }) => {
                const { columns, classes, rowHeight, onRowClick } = this.props;
                return (
                    <TableCell
                        component="div"
                        className={clsx(classes.tableCell, classes.flexContainer, {
                                [classes.noClick]: onRowClick == null,
                        })}
                        variant="body"
                        style={{ height: rowHeight }}
                        align={
                                (columnIndex != null && columns[columnIndex].numeric) || false
                                    ? 'right'
                                    : 'left'
                        }
                    >
                            {cellData}
                    </TableCell>
                );
        };

        linkCellRenderer = (urlGetter : ({ rowData } : {rowData: any}) => { label : string, url : string }) : TableCellRenderer => ({ rowData, columnIndex }) => {
            const { columns, classes, rowHeight, onRowClick } = this.props;
            const { label, url } = urlGetter( { rowData });
            return (
                <TableCell
                    component="div"
                    className={clsx(classes.tableCell, classes.flexContainer, {
                        [classes.noClick]: onRowClick == null,
                    })}
                    variant="body"
                    style={{ height: rowHeight }}
                    align={
                        (columnIndex != null && columns[columnIndex].numeric) || false
                            ? 'right'
                            : 'left'
                    }
                >
                    <a href={ url }> { label } </a>
                </TableCell>
            );
        };

        headerRenderer = ({
                                  label,
                                  columnIndex,
                          }: TableHeaderProps & { columnIndex: number }) => {
                const { headerHeight, columns, classes } = this.props;

                return (
                    <TableCell
                        component="div"
                        className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
                        variant="head"
                        style={{ height: headerHeight }}
                        align={columns[columnIndex].numeric || false ? 'right' : 'left'}
                    >
                            <span>{label}</span>
                    </TableCell>
                );
        };

        render() {
                const { classes, columns, rowHeight, headerHeight, ...tableProps } = this.props;
                return (
                    <AutoSizer>
                            {({ height, width }) => (
                                <Table
                                    height={height}
                                    width={width}
                                    rowHeight={rowHeight!}
                                    gridStyle={{
                                            direction: 'inherit',
                                    }}
                                    headerHeight={headerHeight!}
                                    className={classes.table}
                                    {...tableProps}
                                    rowClassName={this.getRowClassName}
                                    scrollToAlignment={"start"}
                                >
                                        {columns.map(({ dataKey, ...other }, index) => {
                                                return (
                                                    <Column
                                                        key={dataKey}
                                                        headerRenderer={(headerProps) =>
                                                            this.headerRenderer({
                                                                    ...headerProps,
                                                                    columnIndex: index,
                                                            })
                                                        }
                                                        className={classes.flexContainer}
                                                        cellRenderer={ other.urlGetter ? this.linkCellRenderer(other.urlGetter) : this.cellRenderer}
                                                        cellDataGetter={ other.dataGetter }
                                                        dataKey={dataKey}
                                                        {...other}
                                                    />
                                                );
                                        })}
                                </Table>
                            )}
                    </AutoSizer>
                );
        }
}

const defaultTheme = createTheme();
const VirtualizedTable = withStyles(styles, { defaultTheme })(MuiVirtualizedTable);
export default VirtualizedTable;
