export type IdentityProviderResponse = {
    providerName: string;
    type: IdentityProviderType;
    idpIdentifiers: [string];
    attributeMapping: ClientAttributes;
}
export type ClientAttributes = {
    [key: string]: any;
}
export enum IdentityProviderType {
    SAML = "SAML",
    OIDC = "OIDC"
}
