import React, {useEffect} from 'react';

import {ClientResponse} from "../../models/ClientResponse";
import ClientService from "../../services/ClientService";

import HeaderBar from "../../components/HeaderBar";
import AllClientsList from "../../components/AllClientsList";

import {Breadcrumbs, Typography, Fab, Link, Container, Theme} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: 8,
            },
        },
        extendedIcon: {
            marginRight: 8,
        },
        fab: {
            position: 'fixed',
            float: 'right',
            bottom: 15,
            right: 15,
        },
    }),
);

const Clients = () => {
    const classes = useStyles();

    const [clients, setClients] = React.useState<ClientResponse[]>([]);

    useEffect(() => {
        (async () => {
            const {data} = await ClientService.getClients();
            setClients(data);
        })();
    }, []);

    return <header>
        <HeaderBar/>
        <Container maxWidth={"xl"}>
            <Breadcrumbs aria-label="breadcrumb" id="breadcrumb">
                <Link color="inherit" href="/">
                    EMS Management
                </Link>
                <Typography color="textPrimary">Clients</Typography>
            </Breadcrumbs>
            <h1 id="title">Clients</h1>
            <AllClientsList clients={clients} />
            <div className={classes.root}>
                <Fab id="addNewClient" color="secondary" aria-label="add" variant="extended" className={classes.fab} size="large"
                     component="a" href="clients/new">
                    <AddIcon className={classes.extendedIcon}/>Add New
                </Fab>
            </div>
        </Container>
    </header>
}

export default Clients;
