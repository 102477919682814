import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({   title,
                                        contentText,
                                        btnAgreeText,
                                        btnDisagreeText,
                                        open,
                                        closeBtnAgreeHandler,
                                        closeBtnDisagreeHandler,
                                        closeDialogHandler
                                    }: { title: string,
                                        contentText: string,
                                        btnAgreeText: string,
                                        btnDisagreeText: string,
                                        open: boolean,
                                        closeBtnAgreeHandler: () => void,
                                        closeBtnDisagreeHandler: () => void,
                                        closeDialogHandler: () => void }) {
    return (
            <Dialog
                open={open}
                onClose={closeDialogHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <span dangerouslySetInnerHTML={{ __html: contentText }} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeBtnDisagreeHandler}>{btnDisagreeText}</Button>
                    <Button onClick={closeBtnAgreeHandler} autoFocus>
                        {btnAgreeText}
                    </Button>
                </DialogActions>
            </Dialog>
    );
}
