import HeaderBar from "../../components/HeaderBar";
import {Breadcrumbs, Container, Link, Typography} from "@mui/material";
import React, {useState, useEffect} from "react";
import {useParams} from 'react-router-dom';
import CreateJourneyForm from "../../components/CreateJourneyForm";
import {JourneyTemplate} from "../../models/JourneyTemplate";
import ClientService from "../../services/ClientService";

const AddJourney = () => {
    const {clientId, brandId} = useParams();

    const [templates, setTemplates] = useState<JourneyTemplate[]>([])

    useEffect(()=>{
        ClientService.getJourneyTemplates()
            .then(response => {
                setTemplates(response.data);
            })
    }, [])

    return <header>
        <HeaderBar/>
        <Container maxWidth={"xl"}>
            <Breadcrumbs aria-label="breadcrumb" id="breadcrumb">
                <Link color="inherit" href="/">
                    EMS Management
                </Link>
                <Link color="inherit" href="/clients">
                    Clients
                </Link>
                <Link color="inherit" href={"/clients/" + clientId}>
                    {clientId}
                </Link>
                <Typography color="inherit">
                    Brands
                </Typography>
                <Link color="inherit" href={"/clients/" + clientId + "/brands/" + brandId}>
                    {brandId}
                </Link>
                <Typography color="inherit">
                    Journeys
                </Typography>
                <Typography color="textPrimary">Add Journey</Typography>
            </Breadcrumbs>
            <h1 id="title">Add Journey</h1>
            <p>
                <span>Journey Editor Tool: </span>
                <a href={"/uk-rtw-journey-editor.html"} target={"_blank"} rel={"noreferrer"}> UK-RTW</a>
            </p>
            <CreateJourneyForm clientId={clientId} brandId={brandId} templates={templates}/>
        </Container>
    </header>
}

export default AddJourney;
