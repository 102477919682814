import { ChangeEvent } from "react";
import { BrandResponse } from "../../../models/BrandResponse";
import { UserGroupType } from "../../../models/CreateUserRequest";
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";

type UserEntryProps = {
    idx: number;
    user: string;
    onEmailChange: (event: ChangeEvent) => void;
    brands: any;
    brandOptions: BrandResponse[];
    onBrandsChange: (event: SelectChangeEvent) => void;
    groups: any;
    groupOptions: UserGroupType[];
    onGroupsChange: (event: SelectChangeEvent) => void;
}

const UserEntry = ({idx, user, onEmailChange, brands, brandOptions, onBrandsChange, groups, groupOptions, onGroupsChange}: UserEntryProps) => {
    return <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}>
            <TextField
                fullWidth
                label="Email"
                value={user}
                onChange={onEmailChange}
            />
        </Grid>
        <Grid item xs={5}>
            <FormControl fullWidth>
                <InputLabel id={`brand-select-label-${idx}`}>Brands</InputLabel>
                <Select
                    multiple
                    labelId={`brand-select-label-${idx}`}
                    value={brands}
                    label="Brands"
                    onChange={onBrandsChange}
                >
                    {brandOptions.map(brand =>
                        (<MenuItem
                            key={brand.id + idx}
                            value={brand.id}>
                            {brand.name}
                        </MenuItem>))}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={3}>
            <FormControl fullWidth>
                <InputLabel id={`group-select-label-${idx}`}>Groups</InputLabel>
                <Select
                    multiple
                    labelId={`group-select-label-${idx}`}
                    value={groups}
                    label="Groups"
                    onChange={onGroupsChange}
                >
                    {groupOptions.map(group =>
                        (<MenuItem
                            key={group.toString() + idx}
                            value={group.toString()}>
                            {group.toString()}
                        </MenuItem>))}
                </Select>
            </FormControl>
        </Grid>
    </Grid>
}

export default UserEntry;