import HeaderBar from "../../components/HeaderBar";
import {Breadcrumbs, Container, Link, Typography} from "@mui/material";
import React from "react";
import {useParams} from 'react-router-dom';
import CreateBrandForm from "../../components/CreateBrandForm";

const AddBrand = () => {
    const {clientId} = useParams();

    return <header>
        <HeaderBar/>
        <Container maxWidth={"xl"}>
            <Breadcrumbs aria-label="breadcrumb" id="breadcrumb">
                <Link color="inherit" href="/">
                    EMS Management
                </Link>
                <Link color="inherit" href="/clients">
                    Clients
                </Link>
                <Link color="inherit" href={"/clients/" + clientId}>
                    {clientId}
                </Link>
                <Typography color="inherit">
                    Brands
                </Typography>
                <Typography color="textPrimary">Add Brand</Typography>
            </Breadcrumbs>
            <h1 id="title">Add Brand</h1>
            <CreateBrandForm clientId={clientId}/>
        </Container>
    </header>
}

export default AddBrand;
