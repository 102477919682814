import {useCallback, useEffect, useState} from "react";
import ReactFlow, {
    addEdge,
    Background,
    Connection,
    Edge, MarkerType,
    useEdgesState,
    useNodesState
} from "reactflow";
import FloatingEdge from "../common/flow/FloatingEdge";
import {lightGreen,red,lightBlue,yellow,pink,blue,blueGrey,brown,purple,teal,lime,indigo,cyan,grey,orange,green} from "@mui/material/colors";
import DialogContainer from "../common/DialogContainer";
import {JourneyStepTransition} from "../../models/JourneyResponse";
import {Grid, TextField} from "@mui/material";
import "reactflow/dist/style.css";
import "../common/flow/hideConnector.css"

const colors: string[] = [
    lightGreen[700],
    red[700],
    lightBlue[700],
    yellow[900],
    lime[700],
    brown[700],
    purple[700],
    teal[700],
    pink[700],
    indigo[700],
    cyan[700],
    grey[700],
    orange[700],
    blue[700],
    blueGrey[700],
    green[700],
]

const getColor = (idx: number) : string => {
    if (idx < colors.length) {
        return colors[idx];
    }
    return getColor(idx - colors.length);
}

const edgeTypes = {floating: FloatingEdge};

const JourneyView = ({journeySteps} : {journeySteps: {[key: string]: any }[]} ) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [openActions, setOpenActions] = useState<boolean>(false);
    const [transition, setTransition] = useState<JourneyStepTransition | undefined>(undefined);

    const onConnect = useCallback(
        (params: Edge | Connection) => setEdges((els) => addEdge(params, els)),
        [setEdges]
    );
    const closeActionsDialogueHandler = useCallback(()=>{
        setOpenActions(false);
    }, [setOpenActions])

    useEffect(()=>{
        setNodes(journeySteps.map((step, index) => ({
            id: step["entry_state"],
            data: { label: step["entry_state"] },
            position: { x: (index & 1)? 380: 30, y: 5 + index * 80 },
            style: {borderColor: getColor(index), background: getColor(index) + "20"}
        })));

        setEdges(journeySteps.map((step, index) => step.transitions.map(
            (transition: JourneyStepTransition, transitionIndex: any) => ({
                id: `edge-${index}-${transitionIndex}`,
                source: step["entry_state"],
                target: transition["destination_state"],
                data: {
                    label: transition["actions"] && transition["actions"].length > 0 ? (`${transition["message"]} (${transition["actions"].length})`) : transition["message"],
                    actions: transition["actions"],
                    onLabelClick: transition["actions"] && transition["actions"].length > 0 ? ()=> {
                        setTransition(transition);
                        setOpenActions(true);
                    } : undefined
                },
                animated: true,
                type: "floating",
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 5,
                    height: 5,
                    color: getColor(index),
                },
                style: {
                    stroke: getColor(index),
                    strokeWidth: 5,
                },
                labelStyle: { color: getColor(index), fontWeight: 700 },
            })
        )).flat(2));
    },[journeySteps, setOpenActions, setEdges, setNodes]);

    return (
        <>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                edgeTypes={edgeTypes}
            >
                <Background />
            </ReactFlow>
            {
                transition &&
                <DialogContainer open={openActions} closeButtonHandler={closeActionsDialogueHandler}>
                    <h2>{transition.message} Actions</h2>
                    {
                        transition.actions?.map(
                            (action, index) => <Grid key={`actions-${index}`} id={`actions-${index}`} container spacing={1} sx={{padding: 2}}>
                                <Grid item xs={12}>
                                    <TextField
                                        value={action.type}
                                        label={"type"}
                                        fullWidth={true}
                                        sx={{margin: 1}}
                                    />
                                    <TextField
                                        value={action.inputs && JSON.stringify(action.inputs, null, 2)}
                                        label={"inputs"}
                                        fullWidth={true}
                                        multiline
                                        rows={12}
                                        sx={{margin: 1}}
                                    />
                                </Grid>
                            </Grid>
                        )
                    }
                </DialogContainer>
            }

        </>
    );
};

export default JourneyView;
