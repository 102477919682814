import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import ClientService from "../../services/ClientService";
import HeaderBar from "../../components/HeaderBar";
import { Breadcrumbs, Button, Container, FormControl, Grid, InputLabel, Link, MenuItem, Select, Typography } from "@mui/material";
import { BrandResponse } from "../../models/BrandResponse";
import { PermissionsEntry, UserPermissionsView } from "../../models/UserPermissionsView";
import { UserGroupType } from "../../models/CreateUserRequest";
import UserEntry from "./UserEntry";
import NotificationContext from "../../context/NotificationContext";

type UserRow = PermissionsEntry & {
    email: string; 
}

const UserPermissions = () => {
    const {clientId} = useParams();
    const [brands, setBrands] = useState<BrandResponse[]>([]);
    const [domains, setDomains] = useState<string[]>([]);
    const [domainSelected, setDomainSelected] = useState<string>("");
    const [userPermissions, setUserPermissions] = useState<UserRow[]>([]);
    const [groups, setGroups] = useState<UserGroupType[]>([]);
    
    const {logInfo, logError} = useContext(NotificationContext);
    
    const handleSelectDomain = (evt: any) => {
        setDomainSelected(evt.target.value);
    }

    const handleUpdateEmail = (idx: number) => (evt: any) => {
        const updatedPermissions = [...userPermissions];
        updatedPermissions[idx].email = evt.target.value;
        setUserPermissions(updatedPermissions);
    }

    const handleUpdateBrands = (idx: number) => (evt: any) => {
        const updatedPermissions = [...userPermissions];
        updatedPermissions[idx].brandIds = evt.target.value;
        setUserPermissions(updatedPermissions);
    }

    const handleUpdateGroups = (idx: number) => (evt: any) => {
        const updatedPermissions = [...userPermissions];
        updatedPermissions[idx].groups = evt.target.value.map((group: string) => group.toLowerCase());
        setUserPermissions(updatedPermissions);
    }

    const handleAddNewUser = () => {
        const updatedPermissions = [
            ...userPermissions,
            {email: "", brandIds: [], groups: []}
        ];
        setUserPermissions(updatedPermissions);
    }

    const handleSavePermissions = () => {
        for (const idx in userPermissions) {
            if (userPermissions[idx].email.toLowerCase() !== 'default' && !userPermissions[idx].email.toLowerCase().endsWith("@" + domainSelected.toLowerCase())) {
                logError(`email: ${userPermissions[idx].email} is invalid for the selected domain: ${domainSelected}`);
                return;
            }
        }

        const permissionsToSave: UserPermissionsView = Object.fromEntries(userPermissions.filter(user => user.email.length > 0).map(user => ([user.email, {brandIds: user.brandIds, groups: user.groups}])));
        ClientService.saveUserPermissions(
            {
                domain: domainSelected,
                users: permissionsToSave
            },
            () => {
                logInfo("User permissions saved.")
            },
            (err: any) => {
                logError("Failed to save user permissions: " + err)
            }
        )
    }

    useEffect(() => {
        ClientService.getUserGroups()
            .then(userGroups =>  {
                setGroups(userGroups);
            });
    }, []);

    useEffect(() => {
        ClientService.getBrands(clientId).then(res => {
            setBrands(res.data);
            setDomains(Array.from(new Set(res.data.flatMap(brand => brand.allowedEmailDomains.split(",")))));
        })
    }, [clientId])

    useEffect(() => {
        if (domainSelected.length) {
            ClientService.getUserPermissions(domainSelected).then(res => {
                setUserPermissions(Object.entries(res.data).map(([user, permissions], key) => ({email: user, ...permissions})))
            })
        }
    }, [domainSelected])

    return <header>
        <HeaderBar/>
        <Container maxWidth={"xl"}>
            <Breadcrumbs aria-label="breadcrumb" id="breadcrumb">
                <Link color="inherit" href="/">
                    EMS Management
                </Link>
                <Link color="inherit" href="/clients">
                    Clients
                </Link>
                <Link color="inherit" href={"/clients/" + clientId}>
                    {clientId}
                </Link>
                <Typography color="textPrimary">User Permissions</Typography>
            </Breadcrumbs>
            <Grid container spacing={1} sx={{margin: 1}}>
                <Grid container item>
                    <FormControl fullWidth>
                        <InputLabel id="domain-select-label">Domain</InputLabel>
                        <Select
                            labelId="domain-select-label"
                            value={domainSelected}
                            label="Domain"
                            onChange={handleSelectDomain}
                        >
                            <MenuItem value={""}></MenuItem>
                            {
                                domains.map(domain => <MenuItem value={domain} key={`select-${domain}`}>{domain}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                {
                    domainSelected.length > 0 && <Grid item container xs={12} spacing={1}>
                        <Grid item>Users:</Grid>
                        {
                            userPermissions.map((user, key) => <UserEntry 
                                key={`user-${domainSelected}-${key}`} 
                                idx={key} 
                                user={user.email} 
                                onEmailChange={handleUpdateEmail(key)}
                                brands={user.brandIds}
                                brandOptions={brands}
                                onBrandsChange={handleUpdateBrands(key)}
                                groups={user.groups.map(group => group.toUpperCase())}
                                groupOptions={groups}
                                onGroupsChange={handleUpdateGroups(key)}
                            />)
                        }
                        <Grid container item xs={12}>
                            <Grid item xs={4}>
                                <Button onClick={handleAddNewUser}>Add new user</Button>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={4}>
                                <Button variant="contained" onClick={handleSavePermissions}>Save</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Container>
    </header>
}

export default UserPermissions;