import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertWarning({  title,
                                        contentText,
                                        btnText,
                                        open,
                                        closeHandler
                                    }: { title: string,
                                        contentText: string,
                                        btnText: string,
                                        open: boolean,
    closeHandler: () => void }) {
    return (
            <Dialog
                open={open}
                onClose={closeHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <span dangerouslySetInnerHTML={{ __html: contentText }} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeHandler} autoFocus>{btnText}</Button>
                </DialogActions>
            </Dialog>
    );
}

AlertWarning.defaultProps = {
    title: "",
    btnText: "OK"
}
