import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {Dialog} from "@mui/material";

const DialogContainer = ({
                             open,
                             closeButtonHandler,
                             children
                         }: { open: boolean, closeButtonHandler: () => void, children?: React.ReactNode }) => {
    return <Dialog maxWidth={'lg'} open={open}>
        <div
            style={{
                padding: 30,
                width: 1150,
            }}
        >
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <div style={{height: 0}}>
                    <IconButton
                        aria-label="close"
                        onClick={closeButtonHandler}
                        sx={{
                            height: 35,
                            width: 35,
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </div>
            </div>
            {children ? children : null}
        </div>
    </Dialog>
}

export default DialogContainer;
