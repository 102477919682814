import React, {useMemo, useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {UserResponse} from "../../../models/UserResponse";
import VirtualizedTable from "../../../components/common/VirtualizedTable";
import Paginator from "../../../components/common/Paginator";
import {IndexRange, OverscanIndexRange} from "react-virtualized";
import {TextField, Grid} from "@mui/material";
import { DeleteForever } from '@mui/icons-material';
import {DateUtils} from "../../../utils/DateUtils";
import ClientService from "../../../services/ClientService";

const UsersListView = ({users, reloadUsersCallback}: {users: UserResponse[], reloadUsersCallback: ()=>void}) => {

    const {clientId, brandId} = useParams();
    const [pageCount, setPageCount] = useState<number>(1);
    const [page, setPage] = useState<number>(1);
    const [scrollToIndex, setScrollToIndex] = useState<number | undefined> (undefined);
    const [perPage, setPerPage] = useState<number>(1);
    const [searchString, setSearchString] = useState<string>("");

    const rowsFiltered = useMemo(()=>{
        return users.filter(user => searchString.length === 0
            || user.id.toUpperCase().includes(searchString.toUpperCase())
            || user.emailAddress.toUpperCase().includes(searchString.toUpperCase())
            || user.createdAt.toUpperCase().includes(searchString.toUpperCase())
        )
    },[users, searchString]);

    const setPageHandler = (pageClicked : number) => {
        setScrollToIndex( (pageClicked - 1) * perPage );
        setPage(pageClicked);
    }

    const scrollTableHandler = (info: IndexRange & OverscanIndexRange) => {
        setPageCount( Math.ceil(rowsFiltered.length / perPage) ) ;
        setScrollToIndex(undefined);
        setPage(Math.ceil(info.stopIndex / perPage));
    }

    const searchInputHandler = (event: any) => {
        setSearchString(event.target.value);
    }

    const handleDeleteUser = (userId: string) => {
        if (window.confirm("Are you sure you want to delete this user?") === true) {
            ClientService.deleteUser(clientId, brandId, userId)
              .then((e) => reloadUsersCallback())
              .catch((e) => console.log("failed to delete brand's user"));
        }
    };

    useEffect(()=>{
        // trigger onScroll event, so we can get scroll size to calculate number of pages
        setScrollToIndex(1);
        setTimeout(()=>{
            setScrollToIndex(0);
        },100)
    },[users])


    return <>
        <Grid container alignContent={"center"} justifyContent={"space-between"} sx={{marginBottom: 1}}>
            <h3>Users</h3>
        </Grid>
        <div style={{width: '100%', alignSelf: 'flex-start', margin: 'auto'}}>
            <TextField
                sx={{maxWidth: 400, zoom: 0.8}}
                label={"Search"}
                value={searchString}
                onChange={searchInputHandler}
                fullWidth={true}
                variant="outlined"
            />
        </div>
        <Paginator currentPage={page} pageCount={pageCount}  onPageChange={setPageHandler}/>
        <div style={{height: 230, width: '100%', alignSelf: 'center', margin: 'auto'}}>
            <VirtualizedTable
                onScroll={(info)=>{ setPerPage( Math.floor(rowsFiltered.length / (info.scrollHeight / info.clientHeight) )) }}
                rowHeight={40}
                rowCount={rowsFiltered.length}
                rowGetter={({ index }) => rowsFiltered[index]}
                scrollToIndex={scrollToIndex}
                onRowsRendered={scrollTableHandler}
                columns={[
                    {
                        width: 500,
                        label: 'Email',
                        dataKey: 'emailAddress'
                    },
                    {
                        width: 180,
                        label: 'Given Name',
                        dataKey: 'givenName'
                    },
                    {
                        width: 180,
                        label: 'Family Name',
                        dataKey: 'familyName'
                    },
                    {
                        width: 160,
                        label: 'Created At',
                        dataKey: 'createdAt',
                        dataGetter: ({ rowData, dataKey }) => { return DateUtils.toDisplayDateTime(rowData[dataKey]); }
                    },
                    {
                        width: 180,
                        label: 'Actions',
                        dataKey: 'id',
                        dataGetter: ({ rowData, dataKey }) => { return <DeleteForever onClick={() => handleDeleteUser(rowData[dataKey])} sx={{cursor: 'pointer', }}/>}
                    },
                ]}
            />
        </div>
    </>;
};


export default UsersListView;
