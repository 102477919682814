import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import ClientService from "../../services/ClientService";
import {useFormControls} from "../common/FormControls";

import {Button, Snackbar, Theme} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import {createStyles, makeStyles} from "@mui/styles";

import {
    Autocomplete,
    Box,
    createFilterOptions,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    Alert as SnackAlert
} from "@mui/material"
import {CreateJourneyRequest} from "../../models/CreateJourneyRequest";
import JourneyView from "../JourneyView";
import {JourneyTemplate, JourneyTemplateParam} from "../../models/JourneyTemplate";
import NotificationContext from "../../context/NotificationContext";
import {Country} from "../../models/JourneyResponse";
import CreateLocation from "../common/CreateLocations";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: 8,
                width: '95%',
            },
        },
        start_date: {
            width: '50%', paddingRight: '1%'
        }
        ,
        end_date: {
            width: '50%', paddingLeft: '1%'
        }
    })
);

const initialFormValues = {
    name: "",
    caseTtl: "",
    country: null,
    formSubmitted: false,
    success: false,
    journey: "{}",
    locations: [],
}

const CreateJourneyForm = ({
                               clientId,
                               brandId,
                               templates
                           }: { clientId: string, brandId: string, templates: JourneyTemplate[] }) => {
    const classes = useStyles();

    const navigate = useNavigate();
    const [journeyTypes, setJourneyTypes] = useState<string[]>([]);

    const {logError} = useContext(NotificationContext);

    useEffect(() => {
        ClientService.getJourneyTypes()
            .then(types => setJourneyTypes(types))
            .catch(e => logError("Failed to load journey type"));
    }, [logError])

    const {
        handleInputValue,
        handleFormSubmit,
        setMultipleInputValues,
        formIsValid,
        errors,
        openSuccessSnackbar,
        openFailureSnackbar,
        handleSuccessOnClose,
        handleFailureOnClose,
        values,
        journeyParamInputHandler,
        groupBy
    } = useFormControls({
        initialFormValues: initialFormValues,
        formAction: ({data, onSuccess, onFailure}) => {
            try {
                if (data.template && data.template !== "") {
                    const templateSelected = templates.find(template => template.templateId === values.template);
                    if (!templateSelected) {
                        throw new Error("template not found");
                    }
                    const {name, formSubmitted, success, journey, template, ...params} = data;
                    const caseTtl = parseInt(data.caseTtl);
                    const faceCompareThreshold = parseFloat(data.faceCompareThreshold);
                    const createTemplatedJourneyRequest = {
                        name: name,
                        journeyType: data.journeyType,
                        country: data.country,
                        templateId: template.startsWith("templates/") ? template.substring(10) : template,
                        params: Object.fromEntries(templateSelected.params.map(param => [param.key, params[param.key]])),
                        caseTtl: isNaN(caseTtl) || caseTtl < 1 ? undefined : caseTtl,
                        faceCompareThreshold: isNaN(faceCompareThreshold)? undefined : faceCompareThreshold,
                        locations: data.locations,
                    };
                    ClientService.createTemplatedJourney(createTemplatedJourneyRequest, clientId, brandId, onSuccess, onFailure);
                } else {
                    const caseTtl = parseInt(data.caseTtl);
                    const faceCompareThreshold = parseFloat(data.faceCompareThreshold);
                    const journeyRequest: CreateJourneyRequest = {
                        name: data.name,
                        journeyType: data.journeyType,
                        country: data.country,
                        journey: JSON.parse(data.journey),
                        caseTtl: isNaN(caseTtl) || caseTtl < 1 ? undefined : caseTtl,
                        faceCompareThreshold: isNaN(faceCompareThreshold)? undefined : faceCompareThreshold,
                        locations: data.locations,
                    }
                    ClientService.createJourney(journeyRequest, clientId, brandId, onSuccess, onFailure);
                }
            } catch (e) {
                onFailure(e);
            }
        },
        onSuccess: () => {
            navigate(`/clients/${clientId}/brands/${brandId}`);
        }
    });

    const journeyTemplateChangeHandler = useCallback((e: any) => {
        const template = templates.find(template => template.templateId === e.target.value);
        if (template) {
            setMultipleInputValues({
                [e.target.name]: e.target.value,
                ...Object.fromEntries(template.params.filter(param => param.defaultValue).map(param => [param.key, param.defaultValue]))
            });
        } else {
            handleInputValue(e);
        }
    }, [handleInputValue, setMultipleInputValues, templates])

    const journeySteps = useMemo(() => {
        try {
            const template = templates.find(template => template.templateId === values.template);
            if (template) {
                return template.template["steps"];
            }

            const journey = JSON.parse(values.journey);
            return journey["steps"] || [];
        } catch (e) {
            return [];
        }
    }, [values.template, values.journey, templates])

    const templateSelected = useMemo(() => {
        return templates.find(template => template.templateId === values.template)
    }, [values.template, templates])

    const getCode = (country: string): string => {
        const code = Object.keys(Country).find(key => country === Country[key as keyof typeof Country]);
        return code ? code : '';
    }

    const countryFilterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (country: Country) => {
            return getCode(country) + country;
        }
    });

    const countries: readonly Country[] = Object.keys(Country).map(key => Country[key as keyof typeof Country]);

    return <div>
        <form className={classes.root} autoComplete="off" onSubmit={handleFormSubmit}>
            <Grid container spacing={1}>
                <Grid container item xs={6} spacing={2}>
                    <Grid item xs={12}>
                        <TextField name={"name"} label={"Journey Name"}
                                   required={true} type="text" fullWidth={true}
                                   onChange={handleInputValue} onBlur={handleInputValue}
                                   {...(errors["name"] && {error: true, helperText: errors["name"]})}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="countryAutocomplete"
                            options={countries}
                            autoHighlight
                            getOptionLabel={(country) => getCode(country)}
                            filterOptions={countryFilterOptions}
                            isOptionEqualToValue={(option, value) => option === value}
                            value={Country[values.country as keyof typeof Country]}
                            disableClearable
                            renderOption={(props, option) => (
                                <Box component="li"  {...props}>
                                    {getCode(option)} - {option}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} label="Country" name="country"
                                           onChange={handleInputValue} onBlur={handleInputValue}
                                           required={true} type="text" fullWidth={true}
                                           {...(errors["country"] && {error: true, helperText: errors["country"]})}/>

                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name={"caseTtl"} label={"Case Time-To-Live (number of days)"}
                                   required={false} type="number" fullWidth={true}
                                   onChange={handleInputValue} onBlur={handleInputValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="id-faceCompareThreshold" name={"faceCompareThreshold"} label={"Face compare threshold"}
                                   required={false} type="number" fullWidth={true}
                                   InputProps={{ inputProps: {step: 'any'} }}
                                   onChange={handleInputValue} onBlur={handleInputValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel id="type-selector-label">Journey Type *</InputLabel>
                        <Select
                            fullWidth={true}
                            labelId="type-selector-label"
                            id="type-selector"
                            label="Journey Type"
                            name={"journeyType"}
                            onChange={handleInputValue}
                            onBlur={handleInputValue}
                        >
                            {journeyTypes.map(journeyType => (<MenuItem value={journeyType}>{journeyType}</MenuItem>))}
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl sx={{width: "100%"}}>
                            <InputLabel id="journey-template-helper-label">Journey Template</InputLabel>
                            <Select name={"template"} label={"Journey Template"} labelId="journey-template-helper-label"
                                    fullWidth={true} value={values.template || ""}
                                    onChange={journeyTemplateChangeHandler} onBlur={journeyTemplateChangeHandler}>
                                <MenuItem value={""}>- No Template Selected -</MenuItem>
                                {
                                    templates.map(template =>
                                        <MenuItem
                                            value={template.templateId}
                                            key={template.templateId}
                                        >{template.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <CreateLocation locations={values?.locations} onChange={handleInputValue}></CreateLocation>
                    </Grid>
                    {
                        templateSelected ?
                            Object.entries(groupBy(templateSelected.params, (param: JourneyTemplateParam) => param.group))
                                .map(
                                    ([groupName, params]) => <Grid item container key={"paramGroup-" + groupName}
                                                                   xs={12} component={Paper} sx={{
                                        paddingBottom: 2,
                                        paddingRight: 2,
                                        marginLeft: 2,
                                        marginTop: 2
                                    }}>
                                        <Typography variant={"h6"}>
                                            {groupName}
                                        </Typography>
                                        {
                                            (params as JourneyTemplateParam[])
                                                .sort(({order: a}, {order: b}) => a - b)
                                                .map(param => <Grid item key={param.key} xs={12} sx={{marginTop: 2}}>
                                                    <FormControl sx={{width: "100%"}}>
                                                        <TextField name={param.key} label={param.label}
                                                                   required={true}
                                                                   type={param.type === "Number" ? "number" : "text"}
                                                                   multiline={param.type === "MultiText"}
                                                                   rows={3}
                                                                   fullWidth={true}
                                                                   value={values[param.key] || ""}
                                                                   onChange={journeyParamInputHandler[param.type]}
                                                                   onBlur={journeyParamInputHandler[param.type]}
                                                                   aria-describedby={`${param.key}-helper-text`}
                                                                   {...(errors[param.key] && {
                                                                       error: true,
                                                                       helperText: errors[param.key]
                                                                   })}/>
                                                        <FormHelperText id={`${param.key}-helper-text`}>
                                                            {param.description}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>)
                                        }
                                    </Grid>
                                )
                            :
                            <Grid item xs={12}>
                                <TextField name={"journey"} label={"Journey Details"}
                                           required={true} type="text" fullWidth={true}
                                           multiline={true}
                                           rows={16}
                                           onChange={handleInputValue} onBlur={handleInputValue}
                                           {...(errors["journey"] && {error: true, helperText: errors["journey"]})}/>
                            </Grid>
                    }
                </Grid>
                <Grid container item xs={6}>
                    <JourneyView journeySteps={journeySteps}/>
                </Grid>
            </Grid>
            <br/>
            <div>
                <Button id="save" color="primary" variant="contained" startIcon={<SaveIcon/>} type="submit"
                        disabled={!formIsValid()}>Save</Button>
            </div>
        </form>
        <div id='snackbar'>
            <Snackbar open={openSuccessSnackbar} autoHideDuration={5000}>
                <SnackAlert onClose={handleSuccessOnClose} severity={"success"}>
                    Successfully created journey {values.name}
                </SnackAlert>
            </Snackbar>
            <Snackbar open={openFailureSnackbar} autoHideDuration={5000}>
                <SnackAlert onClose={handleFailureOnClose} severity={"error"}>
                    Could not create journey
                </SnackAlert>
            </Snackbar>
        </div>
    </div>
}

export default CreateJourneyForm;
