import React, {useEffect} from "react";
import {useParams} from 'react-router-dom';

import ClientService from "../../services/ClientService";
import {DocumentTypeResponse} from "../../models/DocumentTypeResponse";
import DocumentTypeService from "../../services/DocumentTypeService";
import {ReminderConfigurationResponse} from "../../models/ReminderConfigurationResponse";
import useDebounce from "../../hooks/Debounce";
import {ClientDetailsResponse} from "../../models/ClientDetailsResponse";

import HeaderBar from "../../components/HeaderBar";
import ClientDetailForm from "../../components/ClientDetailForm";
import {Breadcrumbs, Button, Container, Link, Typography} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ClientDetail = () => {
    const [client, setClient] = React.useState<ClientDetailsResponse>();
    const [left, setLeft] = React.useState<DocumentTypeResponse[]>([]);
    const [right, setRight] = React.useState<DocumentTypeResponse[]>([]);
    const [checked, setChecked] = React.useState<DocumentTypeResponse[]>([]);
    const [reminderConfigs, setReminderConfigs] = React.useState<{[id:number]: ReminderConfigurationResponse}>({});
    const {clientId} = useParams();
    const debouncedReminderConfigs : {[id:number]: ReminderConfigurationResponse} = useDebounce(reminderConfigs, 400);

    const navigate = useNavigate();
    const setPermissionsButtonHandler = () => {
        navigate(`/clients/${clientId}/permissions`);
    }

    useEffect(() => {
        Promise.all([ClientService.getClient(clientId), ClientService.getBrands(clientId)])
            .then(
                ([clientResponse, brandsResponse]) => {
                    setClient({
                        ...clientResponse.data,
                        brands: brandsResponse.data
                    })
                }
            )

        const documentTypesData = async () => {
            return await DocumentTypeService.getDocumentTypes();
        }
        const getAssignedDocumentTypes = async () => {
            return await ClientService.getAssignedDocumentTypes(clientId);
        }
        const getAssignedReminderConfigurations = async () => {
            return await ClientService.getAssignedReminderConfigurations(clientId);
        }

        Promise.all([documentTypesData(), getAssignedDocumentTypes(), getAssignedReminderConfigurations()])
            .then(([availableTypes, assignedTypes, assignedReminders]) => {
            setRight(assignedTypes.data);
            const existingNames = assignedTypes.data.map(obj1 => obj1.name);
            setLeft(availableTypes.data.filter(obj => !existingNames.includes(obj.name)));

            const reminders = Object.fromEntries(assignedReminders.data.map((reminder, index) => [index, reminder]));
            setReminderConfigs(reminders);
        })
    }, [clientId]);


    useEffect(() => {

        function updateValues(debouncedReminderConfigs: {[id:number]: ReminderConfigurationResponse}){
            const toSave =  Object.values(debouncedReminderConfigs).length === 1
                && debouncedReminderConfigs[0] !== undefined
            && debouncedReminderConfigs[0].daysToRemind === 0 ? [] : debouncedReminderConfigs

            ClientService.storeReminderConfigurationsForClient(clientId,
                    Object.values(toSave));
        }

        if (debouncedReminderConfigs){
            updateValues(debouncedReminderConfigs)
        }
    }, [debouncedReminderConfigs, clientId]);

    function not(a: DocumentTypeResponse[], b: DocumentTypeResponse[] | undefined) {
        return a?.filter((value) => b?.indexOf(value) === -1);
    }

    function intersection(a: DocumentTypeResponse[] | undefined, b: DocumentTypeResponse[] | undefined) {
        return a?.filter((value) => b?.indexOf(value) !== -1);
    }

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    async function handleCheckedLeft(ids: DocumentTypeResponse[] | undefined) {
        if (ids) {
            setLeft(left?.concat(ids));
            const newRightState = not(right, rightChecked);
            setRight(newRightState);
            await ClientService.storeDocumentTypesForClient(clientId, newRightState);
            setChecked([]);
        }
    }

    async function handleCheckedRight(ids: DocumentTypeResponse[] | undefined) {
        if (ids) {
            let newRightState = right?.concat(ids);
            setRight(newRightState);
            await ClientService.storeDocumentTypesForClient(clientId, newRightState);
            setLeft(not(left, leftChecked));
            setChecked([]);
        }
    }

    async function addReminder() {
        const entries = Object.entries(reminderConfigs);

        if(entries.length <= 2){
            const reminderConfigCopy = {...reminderConfigs}
            reminderConfigCopy[entries.length] = {daysToRemind: 0}
            setReminderConfigs(reminderConfigCopy);
        }
    }

    async function removeReminder(idx: number) {
        const reminderConfigCopy= {...reminderConfigs}
        delete reminderConfigCopy[idx];
        setReminderConfigs(reminderConfigCopy);
    }

    async function handleReminders(idx: number, e: any) {
        const {value} = e.target;
        const reminderConfigCopy= {...reminderConfigs}
        reminderConfigCopy[idx] = {daysToRemind: Number(value)}
        setReminderConfigs(reminderConfigCopy);
    }

    return <header>
        <HeaderBar/>
        <Container maxWidth={"xl"}>
            <Breadcrumbs aria-label="breadcrumb" id="breadcrumb">
                <Link color="inherit" href="/">
                    EMS Management
                </Link>
                <Link color="inherit" href="/clients">
                    Clients
                </Link>
                <Typography color="textPrimary">{client?.name}</Typography>
            </Breadcrumbs>
            <ClientDetailForm clientDetails={client} left={left} right={right} handleCheckedLeft={handleCheckedLeft}
                              handleCheckedRight={handleCheckedRight} checkSetter={setChecked} checked={checked}
                              addReminder={addReminder} reminderConfigs={reminderConfigs}
                              handleReminders={handleReminders} removeReminder={removeReminder}/>
            <Button sx={{marginTop: 4}} variant="contained" onClick={setPermissionsButtonHandler}>Set User Permissions</Button>
        </Container>
    </header>
}

export default ClientDetail;
