import React from 'react';
import {Navigate, Route, Routes as Switch} from "react-router-dom";
import Home from "./pages/Home";
import Authorization from "./pages/Authorization";
import Clients from "./pages/Clients";
import AddClient from "./pages/AddClient";
import AuthenticationService from "./services/AuthenticationService/AuthenticationService";
import ClientDetail from "./pages/ClientDetail";
import BrandDetail from './pages/BrandDetails';
import AddBrand from "./pages/AddBrand";
import AddJourney from "./pages/AddJourney";
import ArchiveCases from "./pages/ArchiveCases";
import UpdateJourney from "./pages/UpdateJourney";
import MediaTools from "./pages/MediaTools";
import UpdateBrandDetails from "./pages/UpdateBrandDetails";
import UpdateJourneyProperties from './pages/UpdateJourneyProperties';
import CaseQuery from "./pages/CaseQuery";
import ServiceStatus from './pages/ServiceStatus';
import UserPermissions from './pages/UserPermissions';

const Routes = () => {
    return (
        <main>
            <Switch>
                <Route path="/" element={<Home/>} exact/>
                <Route path="/login" element={Authorization()}/>
                {
                    AuthenticationService.isAuthenticated() ?
                        <React.Fragment>
                            <Route path='/clients' element={<Clients/>} exact/>
                            <Route path='/clients/new' element={<AddClient/>} exact/>
                            <Route path='/clients/:clientId' element={<ClientDetail/>} exact/>
                            <Route path='/clients/:clientId/permissions' element={<UserPermissions/>} exact/>
                            <Route path='/clients/:clientId/brands/new' element={<AddBrand/>} exact/>
                            <Route path='/clients/:clientId/brands/:brandId' element={<BrandDetail/>} exact/>
                            <Route path='/clients/:clientId/brands/:brandId/edit' element={<UpdateBrandDetails/>} exact/>
                            <Route path='/clients/:clientId/brands/:brandId/journeys/new' element={<AddJourney/>} exact/>
                            <Route path='/clients/:clientId/brands/:brandId/journeys/:journeyId' element={<UpdateJourney/>}/>
                            <Route path='/clients/:clientId/brands/:brandId/journeys/:journeyId/props' element={<UpdateJourneyProperties/>}/>
                            <Route path='/cases/archive' element={<ArchiveCases/>} exact/>
                            <Route path='/cases/query' element={<CaseQuery/>} exact/>
                            <Route path='/media' element={<MediaTools/>} exact/>
                            <Route path='/status' element={<ServiceStatus/>} exact/>
                        </React.Fragment>
                        : null
                }
                <Route path="*" element={<Navigate to="/" replace />} />
            </Switch>
        </main>
    )
}

export default Routes;
