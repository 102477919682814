import React, {useState} from 'react';
import HeaderBar from "../../components/HeaderBar";
import {Button, Container, TextField} from "@mui/material";
import CaseService from "../../services/CaseService";

const CaseQuery = () => {

    const [queryString, setQueryString] = useState("");
    const [result, setResult] = useState("");

    const queryInputHandler = (evt: any) => {
        setQueryString(evt.target.value);
    }
    const searchHandler = async () => {
        const res = await CaseService.searchCases(queryString);
        setResult(JSON.stringify(res, null, 2));
    }

    return <header>
        <HeaderBar/>
        <Container maxWidth={"xl"}>
            <br/>
            <br/>
            <TextField value={queryString}
                       label="Search by Case UID / Consumer Reference / Transaction ID"
                       fullWidth={true}
                       onChange={queryInputHandler}/>
            <Button
                variant={"contained"}
                fullWidth={true}
                onClick={searchHandler}
            >Search</Button>
            <br/>
            <br/>
            <TextField value={result}
                       label="Result"
                       multiline={true}
                       rows={20}
                       inputProps={{readOnly: true,}}
                       fullWidth={true}/>
        </Container>
    </header>
}

export default CaseQuery;
