import React from 'react';

import {DocumentTypeResponse} from "../../models/DocumentTypeResponse";
import { Grid, List, Button, ListItem, Paper, Checkbox, ListItemIcon, ListItemText } from "@mui/material";

import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    paper: {
        width: '100%',
        height: 230,
        overflow: 'auto',
    },
    button: {
        margin: 4,
    },
}));


export const CustomList = ({
                               items,
                               id,
                               checkSetter,
                               checked
                           }:
                               {
                                   items: DocumentTypeResponse[] | undefined,
                                   id: string,
                                   checkSetter: (checks: DocumentTypeResponse[]) => void,
                                   checked: DocumentTypeResponse[] | undefined
                               }) => {

    const handleToggle = (value: DocumentTypeResponse) => () => {
        if (checked) {
            const currentIndex = checked.indexOf(value);
            const newChecked = [...checked];

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }

            checkSetter(newChecked);
        }


    };
    const classes = useStyles();

    return (

        <Paper className={classes.paper}>
            <List dense component="div" role="list" id={id}>
                {items?.map((value: DocumentTypeResponse) => {
                    const listItemId = `transfer-list-item-${id}-${value.name}`;
                    const labelId = `transfer-list-item-${value.name}-label`
                    return (
                        <ListItem id={listItemId} key={value.id} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked?.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{'aria-labelledby': labelId}}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.name}`}/>
                        </ListItem>
                    );
                })}
                <ListItem/>
            </List>
        </Paper>
    );
}

const TransferList = ({left, right, handleCheckedRight, handleCheckedLeft, checkSetter, checked}
                          : {
    left: DocumentTypeResponse[] | undefined,
    right: DocumentTypeResponse[] | undefined,
    handleCheckedRight: (ids: DocumentTypeResponse[] | undefined) => void,
    handleCheckedLeft: (ids: DocumentTypeResponse[] | undefined) => void,
    checkSetter: (ids: DocumentTypeResponse[]) => void
    checked: DocumentTypeResponse[] | undefined
}) => {

    const classes = useStyles();

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className={classes.root}
            item
            xs={12}
        >
            <Grid item xs={5} data-testid="documentTypesLeft"><CustomList items={left} id="documentTypesLeft" checked={checked}
                                          checkSetter={checkSetter}/></Grid>
            <Grid xs={2} item>
                <Grid container direction="column" alignItems="center" rowSpacing={4}>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        id="moveRight"
                        onClick={() => handleCheckedRight(checked)}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        id="moveLeft"
                        onClick={() => handleCheckedLeft(checked)}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid xs={5} item data-testid="documentTypesRight"><CustomList items={right} id={"documentTypesRight"} checked={checked}
                                          checkSetter={checkSetter}/></Grid>
        </Grid>
    );
}

export default TransferList;
