import React, { useCallback, useEffect, useMemo, useState } from 'react';
import HeaderBar from "../../components/HeaderBar";
import {Button, Card, Container, Grid, LinearProgress, MenuItem, Select, TextField} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import ClientService from "../../services/ClientService";

const ServiceStatus = () => {
    const [currentStatus, setCurrentStatus] = useState<any>(undefined);    

    const [statusPeroptyx, setStatusPeroptyx] = useState<string | undefined>(undefined);
    const [descriptionPeroptyx, setDescriptionPeroptyx] = useState<string | undefined>(undefined);
    const [retryPeroptyx, setRetryPeroptyx] = useState<string | undefined>(undefined);

    const [statusNovuna, setStatusNovuna] = useState<string | undefined>(undefined);
    const [descriptionNovuna, setDescriptionNovuna] = useState<string | undefined>(undefined);
    const [retryNovuna, setRetryNovuna] = useState<string | undefined>(undefined);

    const [statusCareID, setStatusCareID] = useState<string | undefined>(undefined);
    const [descriptionCareID, setDescriptionCareID] = useState<string | undefined>(undefined);
    const [retryCareID, setRetryCareID] = useState<string | undefined>(undefined);

    const [statusRTW, setStatusRTW] = useState<string | undefined>(undefined);
    const [descriptionRTW, setDescriptionRTW] = useState<string | undefined>(undefined);
    const [retryRTW, setRetryRTW] = useState<string | undefined>(undefined);

    const [statusI9, setStatusI9] = useState<string | undefined>(undefined);
    const [descriptionI9, setDescriptionI9] = useState<string | undefined>(undefined);
    const [retryI9, setRetryI9] = useState<string | undefined>(undefined);

    const statusPayload = useMemo(()=>{
        return {
            "peroptyx": {
                status: statusPeroptyx,
                offline_description: descriptionPeroptyx === "" ? null : descriptionPeroptyx,
                retry_time: retryPeroptyx === ""? null : retryPeroptyx,
            },
            "novuna": {
                status: statusNovuna,
                offline_description: descriptionNovuna === "" ? null : descriptionNovuna,
                retry_time: retryNovuna === ""? null : retryNovuna,
            },
            "care_id": {
                status: statusCareID,
                offline_description: descriptionCareID === "" ? null : descriptionCareID,
                retry_time: retryCareID === ""? null : retryCareID,
            },
            "rtw": {
                status: statusRTW,
                offline_description: descriptionRTW === "" ? null : descriptionRTW,
                retry_time: retryRTW === ""? null : retryRTW,
            },
            "i9": {
                status: statusI9,
                offline_description: descriptionI9 === "" ? null : descriptionI9,
                retry_time: retryI9 === ""? null : retryI9,
            }
        }
    }, [statusPeroptyx, descriptionPeroptyx, retryPeroptyx, statusNovuna, descriptionNovuna, retryNovuna, statusCareID, descriptionCareID, retryCareID, statusRTW, descriptionRTW, retryRTW, statusI9, descriptionI9, retryI9])

    const statusChanged = useMemo(()=>{
        if (currentStatus && JSON.stringify(currentStatus) !== JSON.stringify(statusPayload)) {
            return true;
        }
        return false;
    }, [currentStatus, statusPayload])

    const saveChangesHandler = useCallback(() => {
        ClientService.updateServiceStatus(
            statusPayload,
            () => {
                setTimeout(()=>{window.location.reload();}, 500);
            },
            (error) => {
                console.error(error)
                alert(error)
            }
        );
    }, [statusPayload])

    const cancelChangesHandler = () => {
        const status = currentStatus;
        setStatusPeroptyx(status.peroptyx.status);
        setDescriptionPeroptyx(status.peroptyx.offline_description || "");
        setRetryPeroptyx(status.peroptyx.retry_time || "");

        setStatusNovuna(status.novuna.status);
        setDescriptionNovuna(status.novuna.offline_description || "");
        setRetryNovuna(status.novuna.retry_time || "");

        setStatusCareID(status.care_id.status);
        setDescriptionCareID(status.care_id.offline_description || "");
        setRetryCareID(status.care_id.retry_time || "");

        setStatusRTW(status.rtw.status);
        setDescriptionRTW(status.rtw.offline_description || "");
        setRetryRTW(status.rtw.retry_time || "");

        setStatusI9(status.i9.status);
        setDescriptionI9(status.i9.offline_description || "");
        setRetryI9(status.i9.retry_time || "");
    }

    useEffect(()=>{
        Promise.all([
            fetch("https://app-icons-fonts-pdf.s3.eu-west-2.amazonaws.com/status/peroptyx.json", {cache: "no-store"}).then(res => res.json()),
            fetch("https://app-icons-fonts-pdf.s3.eu-west-2.amazonaws.com/status/novuna.json", {cache: "no-store"}).then(res => res.json()),
            fetch("https://app-icons-fonts-pdf.s3.eu-west-2.amazonaws.com/status/care_id.json", {cache: "no-store"}).then(res => res.json()),
            fetch("https://app-icons-fonts-pdf.s3.eu-west-2.amazonaws.com/status/rtw.json", {cache: "no-store"}).then(res => res.json()),
            fetch("https://app-icons-fonts-pdf.s3.eu-west-2.amazonaws.com/status/i9.json", {cache: "no-store"}).then(res => res.json())
        ])
        .then(responses => {
            const status = {
                "peroptyx": responses[0],
                "novuna": responses[1],
                "care_id": responses[2],
                "rtw": responses[3],
                "i9": responses[4]
            };

            setStatusPeroptyx(status.peroptyx.status);
            setDescriptionPeroptyx(status.peroptyx.offline_description || "");
            setRetryPeroptyx(status.peroptyx.retry_time || "");

            setStatusNovuna(status.novuna.status);
            setDescriptionNovuna(status.novuna.offline_description || "");
            setRetryNovuna(status.novuna.retry_time || "");

            setStatusCareID(status.care_id.status);
            setDescriptionCareID(status.care_id.offline_description || "");
            setRetryCareID(status.care_id.retry_time || "");

            setStatusRTW(status.rtw.status);
            setDescriptionRTW(status.rtw.offline_description || "");
            setRetryRTW(status.rtw.retry_time || "");

            setStatusI9(status.i9.status);
            setDescriptionI9(status.i9.offline_description || "");
            setRetryI9(status.i9.retry_time || "");

            setCurrentStatus(status);
        })
    }, [setCurrentStatus])

    if (!currentStatus) {
        return <header>
        <HeaderBar/>
            <LinearProgress/>
        </header>
    }

    return <header>
        <HeaderBar/>
        <Container maxWidth={"xl"}>
            <h2>Service Status</h2>
            <Grid container item xs={12} padding={1}>
                <StatusBlock
                    label={"Peroptyx"}
                    serviceStatus={statusPeroptyx}
                    serviceStatusHandler={(evt: any)=>{setStatusPeroptyx(evt.target.value)}}
                    description={descriptionPeroptyx}
                    descriptionHandler={(evt: any)=>{setDescriptionPeroptyx(evt.target.value)}}
                    retry={retryPeroptyx}
                    retryHandler={(evt: any)=>{setRetryPeroptyx(evt.target.value)}}
                />
                <StatusBlock
                    label={"Novuna"}
                    serviceStatus={statusNovuna}
                    serviceStatusHandler={(evt: any)=>{setStatusNovuna(evt.target.value)}}
                    description={descriptionNovuna}
                    descriptionHandler={(evt: any)=>{setDescriptionNovuna(evt.target.value)}}
                    retry={retryNovuna}
                    retryHandler={(evt: any)=>{setRetryNovuna(evt.target.value)}}
                />
                <StatusBlock
                    label={"CareID"}
                    serviceStatus={statusCareID}
                    serviceStatusHandler={(evt: any)=>{setStatusCareID(evt.target.value)}}
                    description={descriptionCareID}
                    descriptionHandler={(evt: any)=>{setDescriptionCareID(evt.target.value)}}
                    retry={retryCareID}
                    retryHandler={(evt: any)=>{setRetryCareID(evt.target.value)}}
                />
                <StatusBlock
                    label={"RTW"}
                    serviceStatus={statusRTW}
                    serviceStatusHandler={(evt: any)=>{setStatusRTW(evt.target.value)}}
                    description={descriptionRTW}
                    descriptionHandler={(evt: any)=>{setDescriptionRTW(evt.target.value)}}
                    retry={retryRTW}
                    retryHandler={(evt: any)=>{setRetryRTW(evt.target.value)}}
                />
                <StatusBlock
                    label={"I9"}
                    serviceStatus={statusI9}
                    serviceStatusHandler={(evt: any)=>{setStatusI9(evt.target.value)}}
                    description={descriptionI9}
                    descriptionHandler={(evt: any)=>{setDescriptionI9(evt.target.value)}}
                    retry={retryI9}
                    retryHandler={(evt: any)=>{setRetryI9(evt.target.value)}}
                />
            </Grid>
            <div style={{marginTop: 15, marginBottom: 50}}>
                <Button color="primary" variant="contained" startIcon={<SaveIcon/>} disabled={!statusChanged} onClick={saveChangesHandler}>Save Changes</Button>
                <Button style={{marginLeft: 15}} color="inherit" variant="contained" disabled={!statusChanged} onClick={cancelChangesHandler}>Cancel</Button>
            </div>
        </Container>
    </header>
}

type StatusBlockProps = {
    label: string;
    serviceStatus?: string;
    description?: string;
    retry?: string;
    serviceStatusHandler: (evt: any)=> void;
    descriptionHandler: (evt: any)=> void;
    retryHandler: (evt: any)=> void;
}

const StatusBlock = ({label, serviceStatus, description, retry, serviceStatusHandler, descriptionHandler, retryHandler }: StatusBlockProps) => {
    return <Grid container margin={1} padding={2} spacing={1} item xs={12} component={Card}>
    <Grid item xs={12}><h3>{label}</h3></Grid>
    <Grid container item xs={2} alignContent={"center"} >Status</Grid>
    <Grid item xs={10}>
        <Select
            value={serviceStatus}                
            onChange={serviceStatusHandler}
            fullWidth
        >
            <MenuItem value={"online"}>Online</MenuItem>
            <MenuItem value={"offline"}>Offline</MenuItem>
        </Select>
    </Grid>
    <Grid container item xs={2} alignContent={"center"} >Offline Description</Grid>
    <Grid item xs={10}>
        <TextField
            value={description}
            onChange={descriptionHandler}
            multiline            
            rows={5}
            fullWidth
        />
    </Grid>
    <Grid container item xs={2} alignContent={"center"} >Retry Time</Grid>
    <Grid item xs={10}>
        <TextField
            value={retry}
            onChange={retryHandler}
            fullWidth
        />
    </Grid>
</Grid>
}

export default ServiceStatus;
