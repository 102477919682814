export class ConfigService {
    static getConfigValue = (key: String) => {
        let value;
        if (process.env && process.env[`${key}`] !== undefined) {
            // get env var value
            value = process.env[`${key}`];
            // console.log(`INFO: got key ${key} from process.env = ${value}`);
        }
        else {
            // no - so try and get value in window.__ENV__
            // this will only be there if it was injected in env.js during build
            // @ts-ignore
            if (window && window.__ENV__ && window.__ENV__[`${key}`] !== undefined) {
                // @ts-ignore
                value =  window.__ENV__[`${key}`]
                // console.log(`INFO: got key ${key} from window.__ENV__ = ${value}`);
            } else {
                // get value from meta tag
                console.log(`ERROR: could not find value for key ${key}`);
            }
        }
        return value;
    }
}
