import React from "react";
import HeaderBar from "../../components/HeaderBar";
import {Breadcrumbs, Container, Link, Typography} from "@mui/material";
import CreateClientForm from "../../components/CreateClientForm";

const AddClient = () => {
    return <header>
        <HeaderBar/>
        <Container maxWidth={"xl"}>
            <Breadcrumbs aria-label="breadcrumb" id="breadcrumb">
                <Link color="inherit" href="/">
                    EMS Management
                </Link>
                <Link color="inherit" href="/clients">
                    Clients
                </Link>
                <Typography color="textPrimary">Add Client</Typography>
            </Breadcrumbs>
            <h1 id="title">Add Client</h1>
            <CreateClientForm id="clientForm"/>
        </Container>
    </header>
}

export default AddClient;
