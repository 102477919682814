import axios from "axios";

class LocalStorageService {
    storeToken = (token: String, ttl: number) => {
        if (!token) {
            throw new Error("No token from login");
        }
        if (ttl <= 0) {
            throw new Error("TTL must be greater than 0");
        }
        const now = () => {
            return new Date()
        }
        const tokenWithExpiry = {
            token: token,
            expiry: now().getTime() + ttl,
        }
        localStorage.setItem("token", JSON.stringify(tokenWithExpiry));
        localStorage.setItem("isLoggedIn", "true");
        axios.defaults.headers.common['Authentication'] = 'Bearer ' + this.getToken();
    }

    isLoggedIn(): boolean {
        return localStorage.getItem("isLoggedIn") === "true";
    }

    getToken(): string {
        const item = localStorage.getItem('token');
        if (item) {
            const parsed = JSON.parse(item);
            return parsed['token'];
        } else {
            localStorage.removeItem('isLoggedIn');
            return '';
        }
    }

    removeToken = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('isLoggedIn')
    }
}

const localStorageServiceSingleton = new LocalStorageService();
export default localStorageServiceSingleton;
