export type JourneyResponse = {
    id: string;
    journeyName: string;
    journeyDetails: JourneyDetails;
    createdAt: string;
    journeyType: string;
    country: Country;
    templateId?: string;
    params?: {[key: string]: string};
}

export type JourneyDetails = {
    steps: JourneyStep[];
    [key: string]: any;
}

type JourneyStep = {
    transitions: JourneyStepTransition[];
    [key: string]: any;
}

export type JourneyStepTransition = {
    message: string;
    actions?: JourneyStepTransitionAction[];
    [key: string]: any;
}

type JourneyStepTransitionAction = {
    type: string;
    inputs?: {[key: string]: any} | null;
}

export enum Country {
    GBR = "United Kingdom",
    IRL = "Ireland",
    USA = "United States of America",
    ZAF = "South Africa",
}
