import React from 'react';
import {ClientDetailsResponse} from "../../models/ClientDetailsResponse";
import {format} from 'date-fns'
import {DocumentTypeResponse} from "../../models/DocumentTypeResponse";
import TransferList from '../TransferList';
import ReminderConfigList from "../ReminderConfigList";
import {Grid, TextField, Theme, Typography} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import BrandsListView from "./BrandsListView";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                paddingTop: 20,
                width: '95%',
            },
        },
        inputBoxTop: {
            paddingTop: '5px',
        },
        inputBoxBetween: {
            paddingLeft: '5px',
        },
        halfWidth: {
            width: '50%'
        },
        transferList: {
            paddingTop: '5px'
        }
    }),
);
const ClientDetailForm = ({
                              clientDetails, left, right, handleCheckedLeft, handleCheckedRight, checkSetter, checked,
                              addReminder, reminderConfigs, handleReminders, removeReminder,
                          }:
                              {
                                  clientDetails: ClientDetailsResponse | undefined,
                                  left: DocumentTypeResponse[] | undefined,
                                  right: DocumentTypeResponse[] | undefined,
                                  handleCheckedLeft: (ids: DocumentTypeResponse[] | undefined) => void,
                                  handleCheckedRight: (ids: DocumentTypeResponse[] | undefined) => void,
                                  checkSetter: (ids: DocumentTypeResponse[]) => void,
                                  checked: DocumentTypeResponse[] | undefined,
                                  addReminder: () => void,
                                  reminderConfigs: any,
                                  handleReminders: (idx: number, e: any) => void,
                                  removeReminder: (idx: number) => void,
                              }) => {
    const classes = useStyles();

    const formatDate = (date: string | undefined, dateFormat: string) => {
        if (date) {
            const dateObj = new Date(date);
            return format(dateObj, dateFormat);
        }
    }
    return <form id='clientDetail' className={classes.root} autoComplete="off">
        <Grid container spacing={1}>
            <Grid container item xs={7} spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        id="clientName"
                        label="Name"
                        value={clientDetails?.name}

                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth={true}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className={classes.inputBoxTop}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="clientContactName"
                        label="Contact Name"
                        value={clientDetails?.contactName}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth={true}
                        variant="outlined"
                        className={classes.inputBoxTop}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="allowedEmailDomains"
                        label="Allowed Email Domains"
                        fullWidth={true}
                        value={clientDetails?.allowedEmailDomains}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }} />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="clientPhoneNumber"
                        label="Contact Phone Number"
                        fullWidth={true}
                        value={clientDetails?.contactPhoneNumber}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="clientContactEmail"
                        label="Contact Email"
                        fullWidth={true}
                        value={clientDetails?.contactEmail}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="clientStartDate"
                        label="Start Date"
                        fullWidth={true}
                        value={formatDate(clientDetails?.startDate, 'yyyy-MM-dd')}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="clientEndDate"
                        label="End Date"
                        fullWidth={true}
                        value={formatDate(clientDetails?.endDate, 'yyyy-MM-dd')}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={5}>
                {
                    clientDetails && <BrandsListView brands={clientDetails.brands} clientId={clientDetails.id}/>
                }
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={6} className={[classes.inputBoxTop, classes.inputBoxBetween, classes.halfWidth].join(' ')}>
                <Typography color={"textSecondary"} align={"left"}>Document Types</Typography>
            </Grid>
            <Grid item xs={6} className={[classes.inputBoxTop, classes.inputBoxBetween, classes.halfWidth].join(' ')}>
                <Typography color={"textSecondary"} align={"left"}>Reminders</Typography>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={6} >
                <TransferList left={left}
                              right={right}
                              handleCheckedRight={handleCheckedRight}
                              handleCheckedLeft={handleCheckedLeft}
                              checkSetter={checkSetter}
                              checked={checked}/>
            </Grid>
            <Grid item xs={6} >
                <ReminderConfigList addReminder={addReminder} reminderConfigs={reminderConfigs}
                                    handleReminders={handleReminders} removeReminder={removeReminder}/>
            </Grid>
        </Grid>
    </form>
}

export default ClientDetailForm;
