import React, {useEffect} from "react";

import {JourneyResponse} from "../../models/JourneyResponse";
import {useFormControls} from "../common/FormControls";
import ClientService from "../../services/ClientService";
import {JourneyTemplate, JourneyTemplateParam} from "../../models/JourneyTemplate";

import {FormControl, FormHelperText, Grid, Paper, TextField, Typography, Button, Snackbar, Theme, Alert as SnackAlert} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: 8,
                width: '95%',
            },
        },
        start_date: {
            width: '50%', paddingRight: '1%'
        }
        ,
        end_date: {
            width: '50%', paddingLeft: '1%'
        }
    })
);

const UpdateJourneyForm = ({
                               journey, templates, onUpdated
                           }: { journey: JourneyResponse | undefined, templates: JourneyTemplate[], onUpdated: () => void }) => {
    const classes = useStyles();
    const template = templates.find(template => template.templateId === journey?.templateId);
    const params = Object.entries(journey?.params || {}).map(([key, value]) => ({key, value}));
    const {
        handleInputValue,
        handleFormSubmit,
        setMultipleInputValues,
        errors,
        openSuccessSnackbar,
        openFailureSnackbar,
        handleSuccessOnClose,
        handleFailureOnClose,
        formIsValid,
        values,
        journeyParamInputHandler,
        groupBy
    } = useFormControls({
        initialFormValues: {},
        formAction: ({data, onSuccess, onFailure}) => {
            ClientService.updateJourneyParameters(data, journey!.id, onSuccess, onFailure);
        },
        onSuccess: onUpdated
    });

    useEffect(() => {
        if (journey) {
            setMultipleInputValues(journey.params)
        }
        // eslint-disable-next-line
    }, [journey])

    return <Grid container sx={{maxWidth: 600}}>
        <TextField name={"name"} label={"Journey Name"}
                   type={"text"} fullWidth={true}
                   value={journey?.journeyName || ""}
                   InputProps={{
                       readOnly: true,
                   }}
        />
        <form className={classes.root} autoComplete="off" onSubmit={handleFormSubmit}>
            {template ?
                Object.entries(groupBy(template.params, (param: JourneyTemplateParam) => param.group))
                    .map(
                        ([groupName, params]) => <Grid item container key={"paramGroup-" + groupName}
                                                       xs={12} component={Paper} sx={{
                            paddingBottom: '2px !important',
                            paddingRight: '2px !important',
                            marginLeft: '2px !important',
                            marginTop: '2px !important'
                        }}>
                            <Typography variant={"h6"}>
                                {groupName}
                            </Typography>
                            {
                                (params as JourneyTemplateParam[])
                                    .sort(({order: a}, {order: b}) => a - b)
                                    .map(param => <Grid item key={param.key} xs={12} sx={{marginTop: 2}}>
                                        <FormControl sx={{width: "100%"}}>
                                            <TextField name={param.key} label={param.label}
                                                       required={true}
                                                       type={param.type === "Number" ? "number" : "text"}
                                                       fullWidth={true}
                                                       value={values[param.key] || ""}
                                                       onChange={journeyParamInputHandler[param.type]}
                                                       onBlur={journeyParamInputHandler[param.type]}
                                                       aria-describedby={`${param.key}-helper-text`}
                                                       {...(errors[param.key] && {
                                                           error: true,
                                                           helperText: errors[param.key]
                                                       })}/>
                                            <FormHelperText id={`${param.key}-helper-text`}>
                                                {param.description}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>)
                            }
                        </Grid>
                    ) :
                (<Grid container spacing={1}>
                    {
                        params.map(
                            (param) => <Grid item key={param.key} xs={12} sx={{marginTop: 2}}>
                                <TextField name={param.key} label={param.key}
                                           required={true} type={"text"} fullWidth={true}
                                           value={values[param.key] || ""}
                                           onChange={handleInputValue} onBlur={handleInputValue}
                                           {...(errors[param.key] && {error: true, helperText: errors[param.key]})}/>

                            </Grid>
                        )
                    }
                </Grid>)
            }
            <br/>
            <div>
                <Button id="save" color="primary" variant="contained" startIcon={<SaveIcon/>} type="submit"
                        disabled={!journey || !formIsValid()}>Save</Button>
            </div>
        </form>
        <div id='snackbar'>
            <Snackbar open={openSuccessSnackbar} autoHideDuration={5000}>
                <SnackAlert onClose={handleSuccessOnClose} severity={"success"}>
                    Successfully updated journey {journey?.journeyName}
                </SnackAlert>
            </Snackbar>
            <Snackbar open={openFailureSnackbar} autoHideDuration={5000}>
                <SnackAlert onClose={handleFailureOnClose} severity={"error"}>
                    Could not update journey
                </SnackAlert>
            </Snackbar>
        </div>
    </Grid>
}


export default UpdateJourneyForm;
